import React from "react";
import { Link } from "react-router-dom";

const CookiePolicy = () => {
  return (
    <div className="content">
      <h2>
        <Link to="/">Home</Link> / Cookie Policy
      </h2>
      <br />

      <p>
        {" "}
        <font size="4">
          <b>THIS DOC IS INCOPORATED INTO THE TERMS OF USE POLICY (AT END)</b>
        </font>
      </p>
      <p>
        <font size="4">
          <b>ShareMy Inc Cookie Policy</b>
        </font>
      </p>
      <p>
        ShareMy Inc believes in being clear and honest about how we collect and process data about you. The following information is designed to
        inform you how we use cookies, and to explain how you can manage them.
      </p>
      <p>
        <b>You already know about cookies and want to adjust your settings?</b>&nbsp;No problem. Just go to your account settings in the app or on the
        website to adjust your privacy preferences which manage cookies. For more information on cookie management tools, see&nbsp;
        <i>“How can you control cookies?”</i>&nbsp;below.
      </p>
      <p>
        <b>You want to know more about cookies and how we use them?&nbsp; </b>Keep reading for more information.
      </p>
      <p>
        <i>
          Note: this Cookie Policy does not explain how we process your personal information outside of our use of cookies. To learn more about how we
          process your personal information, please read our Privacy Policy.
        </i>
      </p>
      <p>
        <b>What are cookies?</b>
      </p>
      <p>
        Cookies are small text files that are sent to or accessed from your web browser or your device’s memory. A cookie typically contains the name
        of the domain (Internet location) from which the cookie originated, the “lifetime” of the cookie (i.e., when it expires) and a randomly
        generated unique number or similar identifier. A cookie may also contain information about your device, such as user settings, browsing
        history and activities conducted while using our services.
      </p>
      <p>
        <b>Are there different types of cookies?</b>
      </p>
      <p>
        <i>First-party and third-party cookies</i>
      </p>
      <p>
        There are first-party cookies and third-party cookies. First-party cookies are placed on your device directly by us. For example, we use
        first-party cookies to adapt our website to your browser’s language preferences and to better understand your use of our website. Third-party
        cookies are placed on your device by our partners and service providers.
      </p>
      <p>
        <i>Session and persistent cookies</i>
      </p>
      <p>
        There are session cookies and persistent cookies. Session cookies only last until you close your browser. We use session cookies for several
        reasons, including to learn more about your use of our website to help you navigate during one single browser session, and to help you use our
        website more efficiently. Persistent cookies have a longer lifespan and last beyond the current session. These types of cookies can be used to
        help you quickly sign in to our website again, to help you navigate quickly to pages, for analytical purposes and for other reasons, described
        below.
      </p>
      <p>
        <b>What about other tracking technologies, such as web beacons and SDKs?</b>
      </p>
      <p>
        Other technologies such as web beacons (also called pixels, tags or clear gifs), tracking URLs or software development kits (SDKs) are used
        similarly to cookies. Web beacons are small graphics files that contain unique identifiers, enabling us to recognize when someone has visited
        our service or opened an email we’ve sent them. Tracking URLs are custom-generated links that help us understand where the traffic to our
        webpages comes from. SDKs are small pieces of code included in apps, which function like cookies and web beacons.
      </p>
      <p>In this document, we also refer to these technologies as “cookies” in this Cookie Policy.</p>
      <p>
        <b>What do we use cookies for?</b>
      </p>
      <p>
        Like other providers of online services, we use cookies to provide, secure and improve our services, including by remembering your
        preferences, recognizing you from previous visits, managing our marketing campaigns and personalizing ads to your interests. To achieve these
        purposes, we may also link information from cookies with other personal information we hold about you.
      </p>
      <p>When you use our services, some or all of the following types of cookies may be set on your device.</p>
      <dl>
        <dd>
          <table cellpadding="5" cellspacing="0">
            <col />
            <col />
            <thead>
              <tr>
                <td bgcolor="#0e3e64">
                  <p>
                    <b>Cookie type</b>
                  </p>
                </td>
                <td bgcolor="#0e3e64">
                  <p>
                    <b>Description</b>
                  </p>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <p>
                    <b>Essential cookies</b>
                  </p>
                </td>
                <td>
                  <p>
                    These cookies are vital to provide our services to you, such as enabling you to log in, remembering your preferences and keeping
                    you safe by detecting malicious activity.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <b>Analytics cookies</b>
                  </p>
                </td>
                <td>
                  <p>These cookies help us understand how our services are used, and help us improve our services for you.</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <b>Advertising &amp; marketing cookies</b>
                  </p>
                </td>
                <td>
                  <p>
                    These cookies are used to determine the effectiveness of our marketing campaigns, and to make the ads you see more relevant to
                    you. They perform functions such as helping us understand how much traffic our marketing campaigns drive on our services,
                    preventing the same ad from continuously re-appearing, ensuring that ads are properly displayed for advertisers, selecting
                    advertisements relevant to you and measuring the number of ads displayed and their performance.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>
                    <b>Social networking cookies</b>
                  </p>
                </td>
                <td>
                  <p>
                    These cookies are used to enable you to share pages and content that you find interesting on our services through third-party
                    social networking and other websites or services. These cookies may also be used for advertising purposes.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </dd>
      </dl>
      <p>
        <b>How can you control cookies?</b>
      </p>
      <p>
        There are several cookie management options available to you. Please note that changes you make to your cookie preferences may degrade our
        services to you. You may get a less satisfying experience as your experience may not be personalized. In some cases, you may be unable to use
        all or part of our services.
      </p>
      <p>
        <i>Tools we provide</i>
      </p>
      <p>
        You can set and adjust your cookie preferences at any time, by heading to your account settings in the app or website and adjusting your
        cookie preferences there.
      </p>
      <p>
        <i>Browser and devices controls</i>
      </p>
      <p>
        Some web browsers provide settings that allow you to control or reject cookies, or that alert you when a cookie is placed on your computer.
        The procedure for managing cookies is slightly different for each Internet browser. You can check the specific steps in your particular
        browser’s help menu.
      </p>
      <p>
        You may also be able to reset device identifiers or opt out from having identifiers collected or processed by using the appropriate setting on
        your mobile device. The procedures for managing identifiers can be different for each device. You can check the specific steps in the help or
        settings menu of your particular device.
      </p>
      <p>
        <i>Interest-based advertising tools</i>
      </p>
      <p>
        Advertising companies may participate in self-regulatory programs that allow you to opt out of any interest-based ads involving them. For more
        information on this, you can visit the following sites:&nbsp;
        <font color="#0563c1">
          <u>
            <a href="http://optout.aboutads.info/?c=2#!/">Digital Advertising Alliance</a>
          </u>
        </font>
        ;&nbsp;
        <font color="#0563c1">
          <u>
            <a href="http://www.youronlinechoices.eu/">Interactive Digital Advertising Alliance</a>
          </u>
        </font>
        ;&nbsp;
        <font color="#0563c1">
          <u>
            <a href="http://youradchoices.com/appchoices">Appchoices</a>
          </u>
        </font>
        &nbsp;(apps only).
      </p>
      <p>
        Opting out means you will not see personalized advertising from the companies that participate in these opt-out programs. Additionally, if you
        delete cookies on your device after you’ve opted out, you will need to opt out again.
      </p>
      <p>
        <b>Google™ Cookies</b>
      </p>
      <p>
        <i>Google™ Maps API Cookies</i>
      </p>
      <p>Some ShareMy services rely on the use of Google™ Maps API Cookies. These types of cookies will be stored on your device.</p>
      <p>
        When browsing this website and using the services relying on Google™ Maps API cookies, you consent to the storage and collection of such
        cookies on your device and to the access, usage and sharing by Google of the data collected thereby.
      </p>
      <p>
        Google™ manages the information and your choices pertaining to Google™ Maps API Cookies via an interface separate from that supplied by your
        browser. For more information, please see&nbsp;
        <font color="#0563c1">
          <u>
            <a href="https://www.google.com/policies/technologies/cookies/">https://www.google.com/policies/technologies/cookies/</a>
          </u>
        </font>
        .
      </p>
      <p>
        <i>Google Analytics</i>
      </p>
      <p>
        We use Google Analytics – a Google service that uses cookies and other data collection technologies to collect information about your use of
        the website and services, in order to report website trends.
      </p>
      <p>
        For more information on how Google collects and processes data, visit&nbsp;
        <font color="#0563c1">
          <u>
            <a href="https://policies.google.com/technologies/partner-sites">https://policies.google.com/technologies/partner-sites</a>
          </u>
        </font>
        . You can opt out of Google Analytics by downloading the Google Analytics opt-out browser add-on at&nbsp;
        <font color="#0563c1">
          <u>
            <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>
          </u>
        </font>
        &nbsp;and opt out of Google’s ad personalization at&nbsp;
        <font color="#0563c1">
          <u>
            <a href="https://adssettings.google.com/">https://adssettings.google.com/.</a>
          </u>
        </font>
      </p>
      <p>
        <b>How to contact us?</b>
      </p>
      <p>If you have questions about this Cookie Policy, please contact us by email below:</p>
      <p>
        <b>CustomerService@ShareMyInc.com</b>
      </p>
    </div>
  );
};

export default CookiePolicy;
