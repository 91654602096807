import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer">
      <p>
        {" "}
        <Link to={"terms-of-use"}>Terms of Use</Link> | <Link to={"privacy-policy"}>Privacy Policy</Link>
        {" | "}
        <Link to={"cookie-policy"}>Cookie Policy</Link> | <Link to={"california-policy"}>California Privacy Notice</Link>
        {" | "}
        <Link to={"community-guide"}>Community Guidelines</Link> | <Link to={"safety-guide"}>Safety Guidelines</Link>{" "}
      </p>
    </div>
  );
};

export default Footer;
