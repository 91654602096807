import React from "react";
import logo from "../images/logo.svg";

const Homepage = () => {
  return (
    <div className="homepage">
      <img src={logo} alt="logo" />
      <h2>Coming soon...</h2>
    </div>
  );
};

export default Homepage;
