import React from "react";
import { useLocation } from "react-router-dom";

const LeftSide = () => {
  const location = useLocation();
  const path = location.pathname;
  return <div className="leftSide" style={{ width: path === "/" ? "50%" : "30%" }}></div>;
};

export default LeftSide;
