import React from "react";
import { Link } from "react-router-dom";

const SafetyGuide = () => {
  return (
    <div className="content">
      <h2>
        <Link to="/">Home</Link> / Safety Guidelines
      </h2>
      <br />

      <p>
        <font size="4">
          <b>ShareMy Safety Guidelines</b>
        </font>
      </p>
      <p>
        Your safety is the most important aspect of any activity. You should always be cautious when interacting with someone you don’t know. Use your
        best judgment and put your safety first, especially when engaging in activities you are not familiar with and/or with new people. While you
        can’t control the actions of others, there are things you can do to help yourself to stay safe.
      </p>
      <p>
        <b>Online Safety and Personal Responsibilities</b>
      </p>
      <ul>
        <li>
          <p>
            <b>Protect Your Personal Information</b>
          </p>
        </li>
      </ul>
      <p>
        Never share personal information, such as your birthdate, social security number, passwords, home or work address, or details about your daily
        routine with people you don’t know. Avoid sharing details about your family such as your children’s names, where they go to school, or their
        ages or genders.
      </p>
      <ul>
        <li>
          <p>
            <b>Never Share Financial Information and Never Send Money Before Participating in an Activity</b>
          </p>
        </li>
      </ul>
      <p>
        Never send money, especially over wire transfer, even if the person claims that it is absolutely necessary before an activity. Wiring money is
        like sending cash — it’s nearly impossible to reverse the transaction or trace where the money went. Never share information that could be
        used to access your financial accounts. Some activities include shared costs for participation. You should agree on the shared costs before
        joining an activity. All costs should be divided equally with all members of an activity including leaders.
      </p>
      <p>
        For tips on avoiding scams, check out some advice from the U.S Federal Trade Commission&nbsp;
        <font color="#0563c1">
          <u>
            <a href="https://www.consumer.ftc.gov/articles/0004-online-dating-scams">on the FTC website</a>
          </u>
        </font>
        &nbsp;
      </p>
      <ul>
        <li>
          <p>
            <b>Watch Out for Suspicious Behavior</b>
          </p>
        </li>
      </ul>
      <p>
        Watch out for scammers who ask for financial help especially if they claim it is an emergency. Be wary of anyone who will not meet in person
        in a safe public space or talk on a phone with video communication—they may not be who they say they are.
      </p>
      <ul>
        <li>
          <p>
            <b>Report All Suspicious and Offensive Behavior</b>
          </p>
        </li>
      </ul>
      <p>
        When someone has crossed the line, we want to know about it. Block and report anyone that violates our terms. Here are some examples of
        violations:
      </p>
      <ul>
        <ul>
          <li>
            <p>Requests for money or donations</p>
          </li>
        </ul>
      </ul>
      <ul>
        <ul>
          <li>
            <p>Harassment, threats, and offensive messages</p>
          </li>
        </ul>
      </ul>
      <ul>
        <ul>
          <li>
            <p>Inappropriate or harmful behavior before, during or after meeting in person</p>
          </li>
          <li>
            <p>Underage users</p>
          </li>
        </ul>
      </ul>
      <ul>
        <ul>
          <li>
            <p>Fraudulent or misleading profiles</p>
          </li>
        </ul>
      </ul>
      <ul>
        <ul>
          <li>
            <p>Spam or solicitations for business or attempts to sell products or services on personal user profiles</p>
          </li>
        </ul>
      </ul>
      <p>You can report any concerns about suspicious behavior from any profile page or messaging window or at safety@ShareMyInc.com.</p>
      <ul>
        <li>
          <p>
            <b>Stay on the Platform</b>
          </p>
        </li>
      </ul>
      <p>
        Keep conversations on the ShareMy platform while you’re getting to know someone. Users with bad intentions often try to move the conversation
        to text, messaging apps, email, or phone quickly and try to access personally sensitive information.
      </p>
      <ul>
        <li>
          <p>
            <b>Protect Your ShareMy Account</b>
          </p>
        </li>
      </ul>
      <p>
        Be sure to pick a strong password, and be especially careful when logging into your account from a public or shared computer. ShareMy will
        never call you or send you an email or text message asking for your username and password information — if you receive an email, text or other
        communication asking for account information, report it immediately.
      </p>
      <p>
        <b>Meet in Public Forums and Stay in Control of Your Own Safety</b>
      </p>
      <ul>
        <li>
          <p>
            <b>Don’t Trust Someone You Do Not Know</b>
          </p>
        </li>
      </ul>
      <p>
        Take time to get to know the other person before agreeing to meet in private settings. Don’t be afraid to ask questions to screen for any red
        flags. A phone or video call can be a useful screening tool before meeting.
      </p>
      <ul>
        <li>
          <p>
            <b>Meet in Public and Stay in Public</b>
          </p>
        </li>
      </ul>
      <p>
        Meet for the first few times in a safe, populated, public place — never at your home, or any other home, or any other private location. If
        someone pressures you to go to a private location alone, do not go with them.
      </p>
      <ul>
        <li>
          <p>
            <b>Be in Control of Your Transportation</b>
          </p>
        </li>
      </ul>
      <p>
        You should stay in control of how you get to and from the activity so that you can leave whenever you decide. It’s always a good idea to have
        a backup plan such as a ride-share app or a friend who can pick you up.
      </p>
      <ul>
        <li>
          <p>
            <b>Tell Friends and Family About Your Plans</b>
          </p>
        </li>
      </ul>
      <p>
        Tell a friend or family member of your plans, including when and where you’re going. Have your cell phone charged and with you at all times
        and enable location detection.
      </p>
      <ul>
        <li>
          <p>
            <b>Don’t Leave Drinks or Personal Items Unattended</b>
          </p>
        </li>
      </ul>
      <p>
        Know where your drink comes from and stay in possession of it at all times — only accept drinks poured or served directly from a known sealed
        container. Many substances that are slipped into drinks to facilitate assault are odorless, colorless, and tasteless. Additionally, keep your
        phone, purse, wallet, and anything containing personal information on you at all times.
      </p>
      <ul>
        <li>
          <p>
            <b>Know Your Limits</b>
          </p>
        </li>
      </ul>
      <p>
        Be aware of the effects of drugs or alcohol that can impair your judgment and your alertness. If someone tries to pressure you to use drugs or
        drink then leave the activity. Additionally know your abilities in physical activities and don’t try anything that you are not comfortable
        with especially if you feel it is unsafe. It’s okay to say no to your participation when it comes to your safety.
      </p>
      <ul>
        <li>
          <p>
            <b>If You Feel Uncomfortable, Leave </b>
          </p>
        </li>
      </ul>
      <p>
        It’s better to end the activity early if you’re feeling uncomfortable. And if your instincts are telling you something is not right or you
        feel unsafe, get to a safe place and reach out to others for help if needed.
      </p>
      <ul>
        <li>
          <p>
            <b>Travel</b>
          </p>
        </li>
      </ul>
      <p>
        Be careful while traveling. Nowhere in the world is without potential risk, and some countries have specific laws that target foreigners
        and/or certain activities. Check out the laws around you when you travel to a new place and research what types of legal protection, if any,
        are available to you. In the event that you’re in unsafe territory, ShareMy suggests that you place your profile in Private Mode. You should
        exercise extra caution if you choose to connect with new people in these countries
      </p>
      <p>
        <b>Consent, Impairment &amp; Help</b>
      </p>
      <ul>
        <li>
          <p>
            <b>Consent </b>
          </p>
        </li>
      </ul>
      <p>
        All activities must include each participants consent. Verbal communication between all members can ensure that you respect each other’s
        boundaries. Participation in an activity can be withdrawn at any time, and participation is never owed to anyone. Do not proceed with any
        activity if a participating member is unable to consent due to the effects of drugs or alcohol. Do not proceed with any activity if the safety
        of any participant is at risk due to the effects of drugs, alcohol or any other impairment.
      </p>
      <p>
        Remember — even if you follow these tips, no method of risk reduction is perfect. Report any incidents to safety@ShareMyInc.com,{" "}
        <b>If you feel you are in immediate danger or need emergency assistance, call 911 (U.S. or Canada) or your local law enforcement agency.</b>
      </p>
      <p>
        <b>Seek Advise, Help or Report any Suspicious Behavior of Criminal Activity to the Following: </b>
      </p>
      <p>
        <b>National Human Trafficking Hotline</b>
      </p>
      <p>
        1-888-373-7888 or text 233733 |&nbsp;
        <font color="#0563c1">
          <u>
            <a href="https://www.humantraffickinghotline.org/">www.humantraffickinghotline.org</a>
          </u>
        </font>
      </p>
      <p>
        <b>National Sexual Violence Resource Center</b>
      </p>
      <p>
        1-877-739-3895 |&nbsp;
        <font color="#0563c1">
          <u>
            <a href="https://www.nsvrc.org/">www.nsvrc.org</a>
          </u>
        </font>
      </p>
      <p>
        <b>National Center for Missing &amp; Exploited Children</b>
      </p>
      <p>
        1-800-THE-LOST (843-5678) |&nbsp;
        <font color="#0563c1">
          <u>
            <a href="https://www.cybertipline.com/">www.cybertipline.com</a>
          </u>
        </font>
      </p>
      <p>
        <b>Cyber Civil Rights Initiative</b>
      </p>
      <p>
        1-844-878-2274 |&nbsp;
        <font color="#0563c1">
          <u>
            <a href="https://www.cybercivilrights.org/">www.cybercivilrights.org</a>
          </u>
        </font>
      </p>
      <p>
        <b>VictimConnect - Crime Victim Resource Center</b>
      </p>
      <p>
        1-855-4VICTIM (855-484-2846) |&nbsp;
        <font color="#0563c1">
          <u>
            <a href="https://www.victimconnect.org/">www.victimconnect.org</a>
          </u>
        </font>
      </p>
      <p>
        <b>FBI Internet Crime Complaint Center</b>
      </p>
      <p>
        <font color="#0563c1">
          <u>
            <a href="https://www.ic3.gov/">www.ic3.gov</a>
          </u>
        </font>
      </p>
      <p>
        We want to hear from you. To submit your suggestions and ideas on how to improve ShareMy visit ShareMyInc.com and send your comments to
        customerservice@sharemyinc.com.
      </p>
      <p>
        <br />
        <br />
      </p>
    </div>
  );
};

export default SafetyGuide;
