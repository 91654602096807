import React from "react";
import { Link } from "react-router-dom";

const TermsOfUse = () => {
  return (
    <div className="content">
      <h2>
        <Link to="/">Home</Link> / Terms of Use
      </h2>
      <br />

      <p>
        <strong>ShareMy Inc Terms of Use Agreement</strong>
      </p>
      <p>
        <em>Effective on 2023-03-24</em>
      </p>
      <p>
        California subscribers: You may cancel your subscription, without penalty or obligation, at any time prior to midnight of the third business
        day following the date you subscribed. If you subscribed using an External Service (e.g., Apple ID, Google Play), you must cancel through your
        External Service, as set forth in more detail in Section 8a. If you subscribed through your Apple ID, refunds are handled by Apple, not
        ShareMy Inc. You can request a refund from Apple through your Apple ID account on your phone or at https://getsupport.apple.com. All other
        users may request a refund by contacting ShareMy Inc Customer Service b, or by mailing or delivering a signed and dated notice that states
        that you, the buyer, are canceling this agreement, or words of similar effect. Please also include your name and the email address, phone
        number, or other unique identifier you used to sign up for your account.
      </p>
      <p>
        This notice shall be sent to: ShareMy Inc, Attn: Cancellations, P.O. Box 267, Lava Hot Springs ID, USA. You may have these Terms of Use
        ("Terms") emailed to you by sending a letter to Terms Inquiries, P.O. Box 267, Lava Hot Springs ID, USA. In accordance with Cal. Civ. Code
        §1789.3, you may report complaints to the Complaint Assistance Unit of the Division of Consumer Services of the California Department of
        Consumer Affairs by contacting them in writing at Consumer Information Division, 1625 North Market Blvd., Suite N112 Sacramento, CA 95834, or
        by telephone at (800) 952- 5210.
      </p>
      <p>
        <strong>
          <em>
            We have included brief summaries at the beginning of each section to make it easier for you to read and understand this agreement. The
            summaries do not replace the text of each section, and you should still read each section in its entirety.
          </em>
        </strong>
      </p>
      <p>
        <strong>1. INTRODUCTION</strong>
      </p>
      <p>
        <strong>
          <em>
            By accessing or using ShareMy Inc's Services, you agree to be bound by this Terms of Use Agreement (the "Terms" or "Agreement"), including
            our
          </em>
        </strong>
        <strong>
          <em>&#xa0;</em>
        </strong>
        <Link to="/privacy-policy">
          <span>Privacy Policy</span>
        </Link>
        <strong>
          <em>,</em>
        </strong>
        <strong>
          <em>&#xa0;</em>
        </strong>
        <Link to="/cookie-policy">
          <span>Cookie Policy</span>
        </Link>
        <strong>
          <em>,</em>
        </strong>
        <strong>
          <em>&#xa0;</em>
        </strong>
        <Link to="/community-guide">
          <span>Community Guidelines</span>
        </Link>
        <strong>
          <em>, and</em>
        </strong>
        <strong>
          <em>&#xa0;</em>
        </strong>
        <Link to="/safety-guide">
          <span>Safety Guidelines</span>
        </Link>
        <strong>
          <em>, so it is important that you read this Agreement and these policies and procedures carefully before you create an account.</em>
        </strong>
      </p>
      <p>
        <strong>
          PLEASE CAREFULLY REVIEW THE DISPUTE RESOLUTION PROVISIONS IN SECTION 15 BELOW. THESE GOVERN THE MANNER IN WHICH CLAIMS WILL BE ADDRESSED
          BETWEEN YOU AND SHAREMY INC. THESE PROVISIONS INCLUDE A MANDATORY PRE-ARBITRATION INFORMAL DISPUTE RESOLUTION PROCESS, AN ARBITRATION
          AGREEMENT, SMALL CLAIMS COURT ELECTION, CLASS ACTION WAIVER, ADDITIONAL PROCEDURES FOR MASS ARBITRATION FILINGS, AND JURY TRIAL WAIVER THAT
          AFFECT YOUR RIGHTS. IN ARBITRATION, THERE IS TYPICALLY LESS DISCOVERY AND APPELLATE REVIEW THAN IN COURT.
        </strong>
      </p>
      <p>
        <strong>
          <em>We may update these Terms from time to time, so check this page regularly for updates.</em>
        </strong>
      </p>
      <p>
        Welcome to ShareMy, operated by ShareMy Inc, in the case of users originating from within the United States and Canada, and operated by
        ShareMy Inc, in the case of users originating from outside of the United States and Canada. As used in this Agreement, the terms "ShareMy
        Inc," "us," "we," the "Company", and "our" shall refer to ShareMy Inc as appropriate. Together you and ShareMy Inc may be referred to as the
        "Parties" or separately as "Party."
      </p>
      <p>
        By accessing or using our Services on ShareMy Inc also known as ShareMyMountain.com, ShareMyRope.com, ShareMyRiver.com, ShareMyWave.com
        ShareMySnow.com and ShareMy.fun (the "Website"), the ShareMy Inc mobile application (the "App"), or any other platforms or services ShareMy
        Inc may offer (collectively, the "Service" or our "Services"), you agree to, and are bound by, this Agreement. This Agreement applies to
        anyone who accesses or uses our Services, regardless of registration or subscription status.
      </p>
      <p>
        Your access and use of our Services is also subject to the&#xa0;
        <Link to="/privacy-policy">
          <span>Privacy Policy</span>
        </Link>
        ,&#xa0;
        <Link to="/cookie-policy">
          <span>Cookie Policy</span>
        </Link>
        ,&#xa0;
        <Link to="/community-guide">
          <span>Community Guidelines</span>
        </Link>
        , and&#xa0;
        <Link to="/safety-guide">
          <span>Safety </span>
        </Link>
        <span>Guidelines</span>&#xa0;and any terms disclosed and agreed to by you when you purchase additional features, products, or services from
        ShareMy Inc ("Additional Terms Upon Purchase"), which are incorporated into this Agreement by reference. If you do not wish to be bound by
        this Agreement, do not access or use our Services.
      </p>
      <p>
        We reserve the right to modify, amend, or change the Terms at any time. Notice of any material change will be posted on this page with an
        updated effective date. In certain circumstances, we may notify you of a change to the Terms via email or other means; however, you are
        responsible for regularly checking this page for any changes. Your continued access or use of our Services constitutes your ongoing consent to
        any changes, and as a result, you will be legally bound by the updated Terms. If you do not accept a change to the Terms, you must stop
        accessing or using our Services immediately.
      </p>
      <p>
        <strong>2. ACCOUNT ELIGIBILITY; YOUR RESPONSIBILITIES</strong>
      </p>
      <p>
        <strong>
          <em>
            Before you create an account on ShareMy Inc, make sure you are eligible to use our Services. This Section also details what you can and
            can’t do when using the Services, as well as the rights you grant ShareMy Inc.
          </em>
        </strong>
      </p>
      <p>
        <strong>
          You are not authorized to create an account or use the Services unless all of the following are true, and by using our Services, you
          represent and warrant that:
        </strong>
      </p>
      <ol>
        <li>You are at least 18 years old;</li>
        <li>You are legally qualified to enter a binding contract with ShareMy Inc;</li>
        <li>
          You are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a
          "terrorist supporting" country;
        </li>
        <li>You are not on any list of individuals prohibited from conducting business with the United States;</li>
        <li>You are not prohibited by law from using our Services;</li>
        <li>
          You have not committed, been convicted of, or pled no contest to a felony or indictable offense (or crime of similar severity), a sex crime,
          or any crime involving violence or a threat of violence, unless you have received clemency for a non-violent crime and we have determined
          that you are not likely to pose a threat to other users of our Services;
        </li>
        <li>You are not required to register as a sex offender with any state, federal or local sex offender registry;</li>
        <li>You do not have more than one account on our Services; and</li>
        <li>
          You have not previously been removed from our Services or our affiliates’ services by us or our affiliates, unless you have our express
          written permission to create a new account.
        </li>
      </ol>
      <p>
        If at any time you cease to meet these requirements, all authorization to access our Services or systems is automatically revoked, and you
        must immediately delete your account.
      </p>
      <p>
        <strong>You agree to:</strong>
      </p>
      <ul>
        <li>
          <span>Comply with these Terms, and check this page from time to time to ensure you are aware of any changes;</span>
        </li>
      </ul>
      <ul>
        <li>
          <span>
            Comply with all applicable laws, including without limitation, privacy laws, intellectual property laws, anti- spam laws, and regulatory
            requirements;
          </span>
        </li>
      </ul>
      <ul>
        <li>
          <span>Use the latest version of the Website and/or App;</span>
        </li>
      </ul>
      <ul>
        <li>
          <span>Treat other users in a courteous and respectful manner, both on and off our Services;</span>
        </li>
      </ul>
      <ul>
        <li>
          <span>Be respectful when communicating with any of our customer care representatives or other employees;</span>
        </li>
      </ul>
      <ul>
        <li>
          <span>Review the</span>
          <span>&#xa0;</span>
          <Link to="/safety-guide">
            <span>Safety Guidelines</span>
          </Link>
          <span>;</span>
        </li>
      </ul>
      <ul>
        <li>
          <span>Maintain a strong password and take reasonable measures to protect the security of your login information.</span>
        </li>
      </ul>
      <p>
        <strong>You agree that you will not:</strong>
      </p>
      <ul>
        <li>
          <span>Misrepresent your identity, age, current or previous positions, qualifications, or affiliations with a person or entity;</span>
        </li>
      </ul>
      <ul>
        <li>
          <span>Use the Services in a way that damages the Services or prevents their use by other users;</span>
        </li>
      </ul>
      <ul>
        <li>
          <span>Use our Services in a way to interfere with, disrupt or negatively affect the platform, the servers, or our Services’ networks;</span>
        </li>
      </ul>
      <ul>
        <li>
          <span>Use our Services for any harmful, illegal, or nefarious purpose;</span>
        </li>
      </ul>
      <ul>
        <li>
          <span>Harass, bully, stalk, intimidate, assault, defame, harm or otherwise mistreat any person;</span>
        </li>
      </ul>
      <ul>
        <li>
          <span>Post or share Prohibited Content (see below);</span>
        </li>
      </ul>
      <ul>
        <li>
          <span>
            Solicit passwords for any purpose, or personal identifying information for commercial or unlawful purposes from other users or disseminate
            another person’s personal information without his or her permission;
          </span>
        </li>
      </ul>
      <ul>
        <li>
          <span>Solicit money or other items of value from another user, whether as a gift, loan, or form of compensation;</span>
        </li>
      </ul>
      <ul>
        <li>
          <span>Use another user’s account;</span>
        </li>
      </ul>
      <ul>
        <li>
          <span>Use our Services in relation to fraud, a pyramid scheme, or other similar practice; or</span>
        </li>
      </ul>
      <ul>
        <li>
          <span>Violate the terms of the license granted to you by ShareMy Inc (see Section 6 below).</span>
        </li>
      </ul>
      <ul>
        <li>
          <span>Disclose private or proprietary information that you do not have the right to disclose;</span>
        </li>
      </ul>
      <ul>
        <li>
          <span>
            Copy, modify, transmit, distribute, or create any derivative works from, any Member Content or Our Content, or any copyrighted material,
            images, trademarks, trade names, service marks, or other intellectual property, content or proprietary information accessible through our
            Services without ShareMy Inc prior written consent;
          </span>
        </li>
      </ul>
      <ul>
        <li>
          <span>Express or imply that any statements you make are endorsed by ShareMy Inc;</span>
        </li>
      </ul>
      <ul>
        <li>
          <span>
            Use any robot, crawler, site search/retrieval application, proxy or other manual or automatic device, method or process to access,
            retrieve, index, "data mine," or in any way reproduce or circumvent the navigational structure or presentation of our Services or its
            contents;
          </span>
        </li>
      </ul>
      <ul>
        <li>
          <span>Upload viruses or other malicious code or otherwise compromise the security of our Services;</span>
        </li>
      </ul>
      <ul>
        <li>
          <span>
            Forge headers or otherwise manipulate identifiers to disguise the origin of any information transmitted to or through our Services;
          </span>
        </li>
      </ul>
      <ul>
        <li>
          <span>"Frame" or "mirror" any part of our Services without ShareMy Inc prior written authorization;</span>
        </li>
      </ul>
      <ul>
        <li>
          <span>
            Use meta tags or code or other devices containing any reference to ShareMy Inc or the platform (or any trademark, trade name, service
            mark, logo or slogan of ShareMy Inc) to direct any person to any other website for any purpose other than to confirm your identity;
          </span>
        </li>
      </ul>
      <ul>
        <li>
          <span>
            Modify, adapt, sublicense, translate, sell, reverse engineer, decipher, decompile or otherwise disassemble any portion of our Services, or
            cause others to do so;
          </span>
        </li>
      </ul>
      <ul>
        <li>
          <span>
            Use or develop any third-party applications that interact with our Services or Member Content or information without our written consent;
          </span>
        </li>
      </ul>
      <ul>
        <li>
          <span>Use, access, or publish the ShareMy Inc application programming interface without our written consent;</span>
        </li>
      </ul>
      <ul>
        <li>
          <span>Probe, scan or test the vulnerability of our Services or any system or network;</span>
        </li>
      </ul>
      <ul>
        <li>
          <span>Encourage, promote, or agree to engage in any activity that violates these Terms; or</span>
        </li>
      </ul>
      <ul>
        <li>
          <span>Create a new account after we suspend or terminate your account, unless you receive our express permission.</span>
        </li>
      </ul>
      <p>
        The license granted to you under these Terms and any authorization to access the Services is automatically revoked in the event that you do
        any of the above.
      </p>
      <p>
        <strong>Prohibited Content—ShareMy Inc prohibits uploading or sharing content that:</strong>
      </p>
      <ul>
        <li>
          <span>Is likely to be deemed offensive or to harass, upset, embarrass, alarm or annoy any other person;</span>
        </li>
      </ul>
      <ul>
        <li>
          <span>Is obscene, pornographic, violent or otherwise may offend human dignity, or contains nudity;</span>
        </li>
      </ul>
      <ul>
        <li>
          <span>Is abusive, insulting or threatening, discriminatory or that promotes or encourages racism, sexism, hatred or bigotry;</span>
        </li>
      </ul>
      <ul>
        <li>
          <span>
            Encourages or facilitates any illegal activity including, without limitation, terrorism, inciting racial hatred or the submission of which
            in itself constitutes committing a criminal offense;
          </span>
        </li>
      </ul>
      <ul>
        <li>
          <span>Is defamatory, libelous, or untrue;</span>
        </li>
      </ul>
      <ul>
        <li>
          <span>
            Relates to commercial activities (including, without limitation, sales, competitions, promotions, and advertising, solicitation for
            services
          </span>
          <span> </span>
          <span>
            unless it is exclusively and specifically uploaded and used on the commercial business platform of ShareMy Inc for such purpose by
            contract with ShareMy Inc.
          </span>
        </li>
      </ul>
      <ul>
        <li>
          <span>Involves the transmission of "junk" mail or "spam";</span>
        </li>
      </ul>
      <ul>
        <li>
          <span>
            Contains any spyware, adware, viruses, corrupt files, worm programs or other malicious code designed to interrupt, damage or limit the
            functionality of or disrupt any software, hardware, telecommunications, networks, servers or other equipment, Trojan horse or any other
            material designed to damage, interfere with, wrongly intercept or expropriate any data or personal information whether from ShareMy Inc or
            otherwise;
          </span>
        </li>
      </ul>
      <ul>
        <li>
          <span>Infringes upon any third party’s rights (including, without limitation, intellectual property rights and privacy rights);</span>
        </li>
      </ul>
      <ul>
        <li>
          <span>Was not written by you or was automatically generated, unless expressly authorized by ShareMy Inc;</span>
        </li>
      </ul>
      <ul>
        <li>
          <span>
            Includes the image or likeness of another person without that person’s consent (or in the case of a minor, the minor’s parent or
            guardian), or is an image or likeness of a minor unaccompanied by the minor’s parent or guardian;
          </span>
        </li>
      </ul>
      <ul>
        <li>
          <span>Is inconsistent with the intended use of the Services; or</span>
        </li>
      </ul>
      <ul>
        <li>
          <span>May harm the reputation of ShareMy Inc or its affiliates.</span>
        </li>
      </ul>
      <p>
        The uploading or sharing of content that violates these Terms ("Prohibited Content") may result in the immediate suspension or termination of
        your account.
      </p>
      <p>
        <strong>3. CONTENT</strong>
      </p>
      <p>
        <strong>
          <em>
            It is important that you understand your rights and responsibilities with regard to the content on our Services, including any content you
            provide or post. You are expressly prohibited from posting inappropriate content.
          </em>
        </strong>
      </p>
      <p>
        While using our Services, you will have access to: (i) content that you upload or provide while using our Services ("Your Content"); (ii)
        content that other users upload or provide while using our Services ("Member Content"); and (iii) content that ShareMy Inc provides on and
        through our Services ("Our Content"). In this agreement, "content" includes, without limitation, all text, images, video, audio, or other
        material on our Services, including information on users’ profiles and in direct messages between users.
      </p>
      <p>
        <strong>3A. YOUR CONTENT</strong>
      </p>
      <p>
        <strong>
          <em>
            You are responsible for Your Content. Don’t share anything that you wouldn’t want others to see, that would violate this Agreement, or
            that may expose you or us to legal liability.
          </em>
        </strong>
      </p>
      <p>
        You are solely responsible and liable for Your Content, and, therefore, you agree to indemnify, defend, release, and hold us harmless from any
        claims made in connection with Your Content.
      </p>
      <p>
        You represent and warrant to us that the information you provide to us or any other user is accurate, including any information submitted
        through social media platforms such as Instagram or other third-party sources (if applicable), and that you will update your account
        information as necessary to ensure its accuracy.
      </p>
      <p>
        The content included on your individual profile should be relevant to the intended use of our Services. You may not display any personal
        contact or banking information, whether in relation to you or any other person (for example, names, home addresses, birthdates or postcodes,
        telephone numbers, email addresses, URLs, credit/debit card or other banking details). If you choose to reveal any personal information about
        yourself to other users, you do so at your own risk. We encourage you to use caution in disclosing any personal information online.
      </p>
      <p>
        Your individual profile may be visible to other people around the world, so be sure that you are comfortable sharing Your Content before you
        post. You acknowledge and agree that Your Content may be viewed by other users, and, notwithstanding these Terms, other users may share Your
        Content with third parties. By uploading Your Content, you represent and warrant to us that you have all necessary rights and licenses to do
        so and automatically grant us a license to use Your Content as provided under Section 7 below.
      </p>
      <p>
        You understand and agree that we may monitor or review Your Content, and we have the right to remove, delete, edit, limit, or block or prevent
        access to any of Your Content at any time in our sole discretion. Furthermore, you understand agree that we have no obligation to display or
        review Your Content.
      </p>
      <p>
        <strong>3B. MEMBER CONTENT</strong>
      </p>
      <p>
        <strong>
          <em>
            While you will have access to Member Content, it is not yours and you may not copy or use Member Content for any purpose except as
            contemplated by these Terms.
          </em>
        </strong>
      </p>
      <p>
        Other users will also share content on our Services. Member Content belongs to the user who posted the content and is stored on our servers
        and displayed at the direction of that user.
      </p>
      <p>
        You do not have any rights in relation to Member Content, and, unless expressly authorized by ShareMy Inc, you may only use Member Content to
        the extent that your use is consistent with our Services’ purpose of allowing users to communicate with and meet other users of ShareMy Inc.
        You may not copy the Member Content or use Member Content for commercial purposes, to spam, to harass, or to make unlawful threats. We reserve
        the right to terminate your account if you misuse Member Content.
      </p>
      <p>
        <strong>3C. OUR CONTENT</strong>
      </p>
      <p>
        <strong>
          <em>ShareMy Inc owns all other content on our Services.</em>
        </strong>
      </p>
      <p>
        Any other text, content, graphics, user interfaces, trademarks, logos, sounds, artwork, images, and other intellectual property appearing on
        our Services is owned, controlled or licensed by us and protected by copyright, trademark and other intellectual property law rights. All
        rights, title, and interest in and to Our Content remains with us at all times.
      </p>
      <p>We grant you a limited license to access and use Our Content as provided under Section 6 below, and we reserve all other rights.</p>
      <p>
        <strong>4. INAPPROPRIATE CONTENT AND MISCONDUCT; REPORTING</strong>
      </p>
      <p>
        <strong>
          <em>ShareMy Inc does not tolerate inappropriate content or behavior on our Services.</em>
        </strong>
      </p>
      <p>
        We are committed to maintaining a positive and respectful ShareMy Inc community, and we do not tolerate any inappropriate content or
        misconduct, whether on or off of the Services (including, but not limited to, on services operated by our affiliates). We encourage you to
        report any inappropriate Member Content or misconduct by other users. You can report a user directly through the "Report a Concern" link on a
        user’s profile or at the bottom of every email. You may also email ShareMy Inc Customer Service at{" "}
        <Link to="/cdn-cgi/l/email-protection" class="__cf_email__" data-cfemail="0665737572696b6374756374706f656346556e6774634b7f4f68652865696b">
          [email&#160;protected]
        </Link>
        .
      </p>
      <p>
        As set forth in our&#xa0;
        <Link to="/privacy-policy">
          <span>Privacy Policy</span>
        </Link>
        , we may share data between our affiliates for the safety and security of our users and may take necessary actions if we believe you have
        violated these Terms, including banning you from our Services and/or our affiliates’ services and/or preventing you from creating new
        accounts. You understand and agree that we may not share information with you regarding your account if doing so would potentially impair the
        safety or privacy of our other users.
      </p>
      <p>
        Member Content is subject to the terms and conditions of Sections 512(c) and/or 512(d) of the Digital Millennium Copyright Act 1998. To submit
        a complaint regarding Member Content that may constitute intellectual property infringement, see Section 12 (Digital Millennium Copyright Act)
        below.
      </p>
      <p>
        <strong>5. PRIVACY</strong>
      </p>
      <p>
        <strong>
          <em>Privacy is important to us. We have a separate policy about it that you should read.</em>
        </strong>
      </p>
      <p>
        For information about how ShareMy Inc and its affiliates collect, use, and share your personal data, please read our&#xa0;
        <Link to="/privacy-policy">
          <span>Privacy Policy</span>
        </Link>
        . By using our Services, you agree that we may use your personal data in accordance with our&#xa0;
        <Link to="/privacy-policy">
          <span>Privacy Policy</span>
        </Link>
        .
      </p>
      <p>
        <strong>6. RIGHTS YOU ARE GRANTED BY SHAREMY INC</strong>
      </p>
      <p>
        <strong>
          <em>ShareMy Inc grants you the right to use and enjoy our Services, subject to these Terms.</em>
        </strong>
      </p>
      <p>
        For as long as you comply with these Terms, ShareMy Inc grants you a personal, worldwide, royalty-free, non-assignable, non-exclusive,
        revocable, and non-sublicensable license to access and use our Services for purposes as intended by ShareMy Inc and permitted by these Terms
        and applicable laws. This license and any authorization to access the Service are automatically revoked in the event that you fail to comply
        with these Terms.
      </p>
      <p>
        <strong>7. RIGHTS YOU GRANT SHAREMY INC</strong>
      </p>
      <p>
        <strong>
          <em>
            You own all of the content you provide to ShareMy Inc, but you also grant us the right to use Your Content as provided in this Agreement.
          </em>
        </strong>
      </p>
      <p>
        By creating an account, you grant to ShareMy Inc a worldwide, perpetual, transferable, sub-licensable, royalty-free right and license to host,
        store, use, copy, display, reproduce, adapt, edit, publish, translate, modify, reformat, incorporate into other works, advertise, distribute
        and otherwise make available to the general public Your Content, including any information you authorize us to access from other social media
        platforms such as Instagram or other third-party sources (if applicable), in whole or in part, and in any way and in any format or medium
        currently known or developed in the future. ShareMy Inc’s license to Your Content shall be non-exclusive, except that ShareMy Inc’s license
        shall be exclusive with respect to derivative works created through use of our Services. For example, ShareMy Inc would have an exclusive
        license to screenshots of our Services that include Your Content.
      </p>
      <p>
        In addition, so that ShareMy Inc can prevent the use of Your Content outside of our Services, you authorize ShareMy Inc to act on your behalf
        with respect to infringing uses of Your Content taken from our Services by other users or third parties. This expressly includes the
        authority, but not the obligation, to send notices pursuant to 17 U.S.C. § 512(c)(3) (i.e., DMCA Takedown Notices) on your behalf if Your
        Content is taken and used by third parties outside of our Services. ShareMy Inc is not obligated to take any action with regard to use of Your
        Content by other users or third parties. ShareMy Inc’s license to Your Content is subject to your rights under applicable law (for example,
        laws regarding personal data protection to the extent the content contains personal information as defined by those laws).
      </p>
      <p>
        In consideration for ShareMy Inc allowing you to use our Services, you agree that we, our affiliates, and our third-party partners may place
        advertising on our Services. By submitting suggestions or feedback to ShareMy Inc regarding our Services, you agree that ShareMy Inc may use
        and share such feedback for any purpose without compensating you.
      </p>
      <p>
        You agree that ShareMy Inc may access, preserve, and disclose your account information, including Your Content, if required to do so by law or
        upon a good faith belief that such access, preservation, or disclosure is reasonably necessary to: (i) comply with legal process; (ii) enforce
        these Terms; (iii) respond to claims that any content violates the rights of third parties; (iv) respond to your requests for customer
        service; or (v) protect the rights, property or personal safety of the Company or any other person.
      </p>
      <p>
        <strong>8. PURCHASES AND AUTOMATICALLY RENEWING SUBSCRIPTIONS</strong>
      </p>
      <p>
        <strong>
          <em>
            You will have the opportunity to purchase products and services from ShareMy Inc. If you purchase a subscription, it will automatically
            renew—and you will be charged—until you cancel.
          </em>
        </strong>
      </p>
      <p>
        ShareMy Inc may offer products and services for purchase through iTunes, Google Play or other external services authorized by ShareMy Inc
        (each, an "External Service," and any purchases made thereon, an "External Service Purchase"). ShareMy Inc may also offer products and
        services for purchase via credit card or other payment processors on the Website or inside the App ("Internal Purchases").&#xa0;If you
        purchase a subscription, it will automatically renew until you cancel, in accordance with the terms disclosed to you at the time of purchase,
        as further described below. If you cancel your subscription, you will continue to have access to your subscription benefits until the end of
        your subscription period, at which point it will expire.
      </p>
      <p>
        Because our Services may be utilized without a subscription, canceling your subscription does not remove your profile from our Services. If
        you wish to fully terminate your membership, you must terminate your membership as set forth in Section 9.
      </p>
      <p>
        ShareMy Inc operates a global business, and our pricing varies by a number of factors. We frequently offer promotional rates - which can vary
        based on region, length of subscription, bundle size and more. We also regularly test new features and payment options.
      </p>
      <p>
        <strong>8A. EXTERNAL SERVICE PURCHASES AND SUBSCRIPTIONS</strong>
      </p>
      <p>
        <strong>
          <em>
            External Service Purchases, including subscriptions, may be processed through the External Service, in which case those purchases must be
            managed through your External Service Account. Subscriptions automatically renew until you cancel.
          </em>
        </strong>
      </p>
      <p>
        When making a purchase on the Service, you may have the option to pay through an External Service, such as with your Apple ID or Google Play
        account ("your External Service Account"), and your External Service Account will be charged for the purchase in accordance with the terms
        disclosed to you at the time of purchase and the general terms applicable to your External Service Account. Some External Services may charge
        you sales tax, depending on where you live, which may change from time to time.
      </p>
      <p>
        If your External Service Purchase includes an automatically renewing subscription, your External Service Account will continue to be
        periodically charged for the subscription until you cancel. After your initial subscription commitment period, and again after any subsequent
        subscription period, the subscription will automatically continue for the price and time period you agreed to when subscribing.
      </p>
      <p>
        <em>
          <u>To cancel a subscription</u>
        </em>
        : If you do not want your subscription to renew automatically, or if you want to change or terminate your subscription, you must log in to
        your External Service Account and follow instructions to manage or cancel your subscription, even if you have otherwise deleted your account
        with us or if you have deleted the App from your device. For example, if you subscribed using your Apple ID, cancellation is handled by Apple,
        not ShareMy Inc. To cancel a purchase made with your Apple ID, go to Settings &gt; iTunes &amp; App Stores &gt; [click on your Apple ID] &gt;
        View Apple ID &gt; Subscriptions, then find your ShareMy Inc subscription and follow the instructions to cancel. You can also request
        assistance at https://getsupport.apple.com. Similarly, if you subscribed on Google Play, cancellation is handled by Google. To cancel a
        purchase made through Google Play, launch the Google Play app on your mobile device and go to Menu &gt; My Apps &gt; Subscriptions, then find
        your ShareMy Inc subscription and follow the instructions to cancel. You can also request assistance at https://play.google.com. If you cancel
        a subscription, you may continue to use the cancelled service until the end of your then-current subscription term. The subscription will not
        be renewed when your then-current term expires.
      </p>
      <p>
        If you initiate a chargeback or otherwise reverse a payment made with your External Service Account, ShareMy Inc may terminate your account
        immediately in its sole discretion, on the basis that you have determined that you do not want a ShareMy Inc subscription. In the event that
        your chargeback or other payment reversal is overturned, please contact&#xa0;
        <Link to="https://www.match.com/faq/contactus">
          <span>Customer </span>
        </Link>
        <span>Service</span>. ShareMy Inc will retain all funds charged to your External Service Account until you cancel your subscription through
        your External Service Account. Certain users may be entitled to request a refund. See Section 8d below for more information.
      </p>
      <p>
        <strong>8B. INTERNAL PURCHASES AND SUBSCRIPTIONS</strong>
      </p>
      <p>
        <strong>
          <em>
            Internal Purchases (including purchases made through our third-party payment processing company), which include subscriptions, are
            processed using the Payment Method you provide on the Website or App. Subscriptions automatically renew until you cancel.
          </em>
        </strong>
      </p>
      <p>
        If you make an Internal Purchase, you agree to pay the prices displayed to you for the Services you’ve selected as well as any sales or
        similar taxes that may be imposed on your payments (and as may change from time to time), and you authorize ShareMy Inc to charge the payment
        method you provide (your "Payment Method"). ShareMy Inc may correct any billing errors or mistakes even if we have already requested or
        received payment. If you initiate a chargeback or otherwise reverse a payment made with your Payment Method, ShareMy Inc may terminate your
        account immediately in its sole discretion, on the basis that you have determined that you do not want a ShareMy Inc subscription. In the
        event that your chargeback or other payment reversal is overturned, please contact&#xa0;
        <Link to="https://www.match.com/faq/contactus">
          <span>Customer Service</span>
        </Link>
        .
      </p>
      <p>
        If your Internal Purchase includes an automatically renewing subscription, your Payment Method will continue to be periodically charged for
        the subscription until you cancel. After your initial subscription commitment period, and again after any subsequent subscription period, your
        subscription will automatically continue for the price and time period you agreed to when subscribing, until you cancel.
      </p>
      <p>
        To cancel a subscription, log in to the Website or App and go to the Settings tool. If you cancel a subscription, you may continue to use the
        cancelled service until the end of your then-current subscription term. The subscription will not be renewed when your then-current term
        expires.
      </p>
      <p>
        You may edit your Payment Method information by using the Settings tool. If a payment is not successfully processed, due to expiration,
        insufficient funds, or otherwise, you remain responsible for any uncollected amounts and authorize us to continue billing the Payment Method,
        as it may be updated. This may result in a change to your payment billing dates.
      </p>
      <p>
        In addition, you authorize us to obtain updated or replacement expiration dates and card numbers for your credit or debit card as provided by
        your credit or debit card issuer. The terms of your payment will be based on your Payment Method and may be determined by agreements between
        you and the financial institution, credit card issuer, or other provider of your chosen Payment Method. Certain users may be entitled to
        request a refund. See Section 8d below for more information.
      </p>
      <p>
        <strong>8C. VIRTUAL ITEMS</strong>
      </p>
      <p>
        <strong>
          <em>Virtual items are non-refundable and subject to certain conditions.</em>
        </strong>
      </p>
      <p>
        From time to time, you may have the opportunity to purchase a limited, personal, non-transferable, non-sublicensable, revocable license to use
        or access special limited-use features such as <u>"</u>
        <u>
          <span>TakeMe</span>
        </u>
        <u>" </u>("Virtual Item(s)") from ShareMy Inc. You may only purchase Virtual Items from us or our authorized partners through our Services.
        Virtual Items represent a limited license right governed by this Agreement, and, except as otherwise prohibited by applicable law, no title or
        ownership in or to Virtual Items is being transferred or assigned to you. This Agreement should not be construed as a sale of any rights in
        Virtual Items.
      </p>
      <p>
        Any Virtual Item balance shown in your account does not constitute a real-world balance or reflect any stored value, but instead constitutes a
        measurement of the extent of your license. Virtual Items do not incur fees for non-use; however, the license granted to you in Virtual Items
        will terminate in accordance with the terms of this Agreement, on the earlier of when ShareMy Inc ceases providing our Services, or your
        account is otherwise closed or terminated.
      </p>
      <p>
        ShareMy Inc, in its sole discretion, reserves the right to charge fees for the right to access or use Virtual Items and/or may distribute
        Virtual Items with or without charge. ShareMy Inc may manage, regulate, control, modify, or eliminate Virtual Items at any time, including
        taking actions that may impact the perceived value or purchase price, if applicable, of any Virtual Items. ShareMy Inc shall have no liability
        to you or any third party in the event that ShareMy Inc exercises any such rights. The transfer of Virtual Items is prohibited, and you shall
        not sell, redeem, or otherwise transfer Virtual Items to any person or entity. Virtual Items may only be redeemed through our Services.
      </p>
      <p>
        ALL PURCHASES AND REDEMPTIONS OF VIRTUAL ITEMS MADE THROUGH OUR SERVICES ARE FINAL AND NON-REFUNDABLE. YOU ACKNOWLEDGE THAT SHAREMY INC IS NOT
        REQUIRED TO PROVIDE A REFUND FOR ANY REASON, AND THAT YOU WILL NOT RECEIVE MONEY OR OTHER COMPENSATION FOR UNUSED VIRTUAL ITEMS WHEN AN
        ACCOUNT IS CLOSED, WHETHER SUCH CLOSURE WAS VOLUNTARY OR INVOLUNTARY.
      </p>
      <p>
        <strong>8D. REFUNDS</strong>
      </p>
      <p>
        <strong>
          <em>
            Unless specifically stated with conditions for refunds with specific terms for refunds at the time of sale, all purchases are
            nonrefundable. Special terms apply in Arizona, California, Colorado, Connecticut, Illinois, Iowa, Minnesota, New York, North Carolina,
            Ohio, Rhode Island, and Wisconsin.
          </em>
        </strong>
      </p>
      <p>
        Unless specifically stated otherwise at the time of sale, all purchases are final and nonrefundable, and there are no refunds or credits for
        partially used periods, except if the laws applicable in your jurisdiction provide for refunds.
      </p>
      <p>
        <u>
          For subscribers residing in Arizona, California, Colorado, Connecticut, Illinois, Iowa, Minnesota, New York, North Carolina, Ohio, Rhode
          Island, and Wisconsin:
        </u>
      </p>
      <p>
        <strong>
          Your Right to Cancel—You may cancel your subscription, without penalty or obligation, at any time prior to midnight of the third business
          day following the date you subscribed.
        </strong>
        &#xa0;In the event that you die before the end of your subscription period, your estate shall be entitled to a refund of that portion of any
        payment you had made for your subscription which is allocable to the period after your death. In the event that you become disabled (such that
        you are unable to use our Services) before the end of your subscription period, you shall be entitled to a refund of that portion of any
        payment you had made for your subscription which is allocable to the period after your disability by providing the Company notice in the same
        manner as you request a refund as described below.
      </p>
      <p>Purchases of Virtual Items are FINAL AND NON-REFUNDABLE.</p>
      <p>
        If any of the above apply to you and you subscribed using your Apple ID, your refund requests are handled by Apple, not ShareMy Inc. To
        request a refund, please contact your External Service directly; for example, using your Apple device, go to Settings &gt; iTunes &amp; App
        Stores &gt; [click on your Apple ID] &gt; View Apple ID &gt; Purchase History. Find the transaction and select "Report a Problem." You can
        also request a refund at https://getsupport.apple.com. For any other purchase, please contact ShareMy Inc Customer Service with your order
        number (see your confirmation email) by mailing or delivering a signed and dated notice which states that you, the buyer, are canceling this
        Agreement, or words of similar effect. Please also include the email address or telephone number associated with your account along with your
        order number. This notice shall be sent to: ShareMy Inc, Attn: Cancellations, P.O. Box 267, Lava Hot Springs ID, USA.
      </p>
      <p>
        <strong>9. ACCOUNT TERMINATION</strong>
      </p>
      <p>
        <strong>
          <em>If you no longer wish to use our Services, or if we terminate your account for any reason, here’s what you need to know.</em>
        </strong>
      </p>
      <p>
        You can delete your account at any time by logging into the Website or App, going to "Settings" (the gear/pencil icon in the top right
        corner), and following the instructions to cancel your membership.&#xa0;
        <strong>
          However, you will need to cancel / manage any External Service Purchases through your External Service Account (e.g., iTunes, Google Play)
          to avoid additional billing.
        </strong>
      </p>
      <p>
        ShareMy Inc reserves the right to investigate and, if appropriate, suspend or terminate your account without a refund if ShareMy Inc believes
        that you have violated these Terms, misused our Services, or behaved in a way that ShareMy Inc regards as inappropriate or unlawful, on or off
        our Services. We reserve the right to make use of any personal, technological, legal, or other means available to enforce the Terms, at any
        time without liability and without the obligation to give you prior notice, including, but not limited to, preventing you from accessing the
        Services.
      </p>
      <p>
        If your account is terminated by you or by ShareMy Inc for any reason, these Terms continue and remain enforceable between you and ShareMy
        Inc, and you will not be entitled to any refund for purchases made. Your information will be maintained and deleted in accordance with
        our&#xa0;
        <Link to="/privacy-policy">
          <span>Privacy Policy</span>
        </Link>
        .
      </p>
      <p>10. NO CRIMINAL BACKGROUND OR IDENTITY VERIFICATION CHECKS</p>
      <p>
        <strong>
          <em>
            ShareMy Inc does not conduct criminal background or identity verification checks on its users. Use your best judgment when interacting
            with others and review our
          </em>
        </strong>
        <strong>
          <em>&#xa0;</em>
        </strong>
        <Link to="/safety-guide">
          <span>Safety Guidelines</span>
        </Link>
        <strong>
          <em>.</em>
        </strong>
      </p>
      <p>
        <strong>
          YOU UNDERSTAND THAT SHAREMY INC DOES NOT CONDUCT CRIMINAL BACKGROUND OR IDENTITY VERIFICATION CHECKS ON ITS USERS OR OTHERWISE INQUIRE INTO
          THE BACKGROUND OF ITS USERS.
        </strong>
        &#xa0;SHAREMY INC MAKES NO REPRESENTATIONS OR WARRANTIES AS TO THE CONDUCT, IDENTITY, INTENTIONS, LEGITIMACY, OR VERACITY OF USERS. SHAREMY
        INC RESERVES THE RIGHT TO CONDUCT—AND YOU AUTHORIZE SHAREMY INC TO CONDUCT—ANY CRIMINAL BACKGROUND CHECK OR OTHER SCREENINGS (SUCH AS SEX
        OFFENDER REGISTER SEARCHES) AT ANY TIME USING AVAILABLE PUBLIC RECORDS, AND YOU AGREE THAT ANY INFORMATION YOU PROVIDE MAY BE USED FOR THAT
        PURPOSE. IF THE COMPANY DECIDES TO CONDUCT ANY SCREENING THROUGH A CONSUMER REPORTING AGENCY, YOU HEREBY AUTHORIZE THE COMPANY TO OBTAIN AND
        USE A CONSUMER REPORT ABOUT YOU TO DETERMINE YOUR ELIGIBILITY UNDER THESE TERMS.
      </p>
      <p>
        YOU ARE SOLELY RESPONSIBLE FOR YOUR INTERACTIONS WITH OTHER USERS. SEX OFFENDER SCREENINGS AND OTHER TOOLS DO NOT GUARANTEE YOUR SAFETY AND
        ARE NOT A SUBSTITUTE FOR FOLLOWING THE&#xa0;
        <Link to="/safety-guide">
          <span>SAFETY GUIDELINES</span>
        </Link>
        &#xa0;AND OTHER SENSIBLE SAFETY PRECAUTIONS. ALWAYS USE YOUR BEST JUDGMENT AND TAKE APPROPRIATE SAFETY PRECAUTIONS WHEN COMMUNICATING WITH OR
        MEETING NEW PEOPLE. COMMUNICATIONS RECEIVED THROUGH THE SERVICE, INCLUDING AUTOMATIC NOTIFICATIONS SENT BY SHAREMY INC, MAY RESULT FROM USERS
        ENGAGING WITH THE SERVICE FOR IMPROPER PURPOSES, INCLUDING FRAUD, ABUSE, HARASSMENT, OR OTHER SUCH IMPROPER BEHAVIOR.
      </p>
      <p>
        Though ShareMy Inc strives to encourage a respectful user experience, it is not responsible for the conduct of any user on or off the Service.
        You agree to use caution in all interactions with other users, particularly if you decide to communicate off the Service or meet in person.
      </p>
      <p>
        <strong>11. DISCLAIMER</strong>
      </p>
      <p>
        <strong>
          <em>
            ShareMy Inc’s Services are provided "as is" and we do not make, and cannot make, any representations about the content or features of our
            Services.
          </em>
        </strong>
      </p>
      <p>
        SHAREMY INC PROVIDES OUR SERVICES ON AN "AS IS" AND "AS AVAILABLE" BASIS AND TO THE EXTENT PERMITTED BY APPLICABLE LAW, GRANTS NO WARRANTIES
        OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE WITH RESPECT TO OUR SERVICES (INCLUDING ALL CONTENT CONTAINED THEREIN),
        INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF SATISFACTORY QUALITY, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR
        NON-INFRINGEMENT. SHAREMY INC DOES NOT REPRESENT OR WARRANT THAT (A) OUR SERVICES WILL BE UNINTERRUPTED, SECURE, OR ERROR FREE, (B) ANY
        DEFECTS OR ERRORS IN OUR SERVICES WILL BE DISCOVERED OR CORRECTED, OR (C) THAT ANY CONTENT OR INFORMATION YOU OBTAIN ON OR THROUGH OUR
        SERVICES WILL BE ACCURATE OR APPROPRIATE FOR YOUR PURPOSES. FURTHERMORE, SHAREMY INC MAKES NO GUARANTEES AS TO THE NUMBER OF ACTIVE USERS AT
        ANY TIME; USERS’ ABILITY OR DESIRE TO COMMUNICATE WITH OR MEET YOU, OR THE ULTIMATE COMPATIBILITY WITH OR CONDUCT BY USERS YOU MEET THROUGH
        THE SERVICES.
      </p>
      <p>
        SHAREMY INC ASSUMES NO RESPONSIBILITY FOR ANY CONTENT THAT YOU OR ANOTHER USER OR THIRD PARTY POSTS, SENDS, OR RECEIVES THROUGH OUR SERVICES;
        NOR DOES SHAREMY INC ASSUME ANY RESPONSIBILITY FOR THE IDENTITY, INTENTIONS, LEGITIMACY, OR VERACITY OF ANY USERS WITH WHOM YOU MAY
        COMMUNICATE WITH THROUGH SHAREMY INC. ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF OUR SERVICES IS ACCESSED AT YOUR OWN
        DISCRETION AND RISK. SHAREMY INC IS NOT RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER HARDWARE, COMPUTER SOFTWARE, OR OTHER EQUIPMENT OR
        TECHNOLOGY INCLUDING, BUT WITHOUT LIMITATION, DAMAGE FROM ANY SECURITY BREACH OR FROM ANY VIRUS, BUGS, TAMPERING, HACKING, FRAUD, ERROR,
        OMISSION, INTERRUPTION, DEFECT, DELAY IN OPERATION OR TRANSMISSION, COMPUTER LINE OR NETWORK FAILURE, OR ANY OTHER TECHNICAL OR OTHER
        DISRUPTION OR MALFUNCTION.
      </p>
      <p>
        <strong>12. DIGITAL MILLENNIUM COPYRIGHT ACT</strong>
      </p>
      <p>
        <strong>
          <em>We take copyright infringement very seriously. We ask you to help us to ensure we address it promptly and effectively.</em>
        </strong>
      </p>
      <p>
        ShareMy Inc has adopted the following policy towards copyright infringement in accordance with the Digital Millennium Copyright Act (the
        "DMCA"). If you believe any Member Content or Our Content infringes upon your intellectual property rights, please submit a notification
        alleging such infringement ("DMCA Takedown Notice") including the following:
      </p>
      <ol>
        <li>
          A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed;
        </li>
        <li>
          Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works at a single online site are covered
          by a single notification, a representative list of such works;
        </li>
        <li>
          Identification of the material claimed to be infringing or to be the subject of infringing activity and that is to be removed or access
          disabled and information reasonably sufficient to permit the service provider to locate the material;
        </li>
        <li>
          Information reasonably sufficient to permit the service provider to contact you, such as an address, telephone number, and, if available, an
          electronic mail;
        </li>
        <li>
          A statement that you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner,
          its agent, or the law; and
        </li>
        <li>
          A statement that, under penalty of perjury, the information in the notification is accurate and you are authorized to act on behalf of the
          owner of the exclusive right that is allegedly infringed.
        </li>
      </ol>
      <p>
        Any DMCA Takedown Notices should be sent to{" "}
        <Link to="/cdn-cgi/l/email-protection" class="__cf_email__" data-cfemail="95d9f0f2f4f9d5c6fdf4e7f0d8ecdcfbf6bbf6faf8">
          [email&#160;protected]
        </Link>
        , or via mail to the following address: Copyright Compliance c/o ShareMy Inc P O Box 267, Lava Hot Springs ID 83246 USA
      </p>
      <p>ShareMy Inc will terminate the accounts of repeat infringers.</p>
      <p>
        <strong>13. ADS AND THIRD-PARTY CONTENT</strong>
      </p>
      <p>
        <strong>
          <em>Like many subscription-based services, there are ads on our websites.</em>
        </strong>
      </p>
      <p>
        Our Services may contain advertisements and promotions offered by third parties and links to other websites or resources. ShareMy Inc may also
        provide non-commercial links or references to third parties within its content. ShareMy Inc is not responsible for the availability (or lack
        of availability) of any external websites or resources or their content. Furthermore, ShareMy Inc is not responsible for, and does not
        endorse, any products or services that may be offered by third-party websites or resources. If you choose to interact with the third parties
        made available through our Services, such party’s terms will govern their relationship with you. ShareMy Inc is not responsible or liable for
        such third parties’ terms or actions.
      </p>
      <p>
        <strong>14. LIMITATION OF LIABILITY</strong>
      </p>
      <p>
        <strong>
          <em>ShareMy Inc’s liability is limited to the maximum extent allowed by applicable law.</em>
        </strong>
      </p>
      <p>
        TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL SHAREMY INC, ITS AFFILIATES, EMPLOYEES, LICENSORS, OR SERVICE PROVIDERS BE
        LIABLE FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, PUNITIVE, FIXED, OR ENHANCED DAMAGES, INCLUDING, WITHOUT LIMITATION,
        LOSS OF PROFITS, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM: (I)
        YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE SERVICES, (II) THE CONDUCT OR CONTENT OF ANY USERS OR THIRD PARTIES ON OR THROUGH
        ANY OF OUR AFFILIATES’ SERVICES OR IN CONNECTION WITH THE SERVICES; OR (III) ANY UNAUTHORIZED ACCESS, USE, OR ALTERATION OF YOUR CONTENT, EVEN
        IF SHAREMY INC HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL
        SHAREMY INC’S AGGREGATE LIABILITY TO YOU FOR ALL CLAIMS RELATING TO THE SERVICES EXCEED THE AMOUNT PAID, IF ANY, BY YOU TO SHAREMY INC FOR THE
        SERVICES DURING THE TWENTY-FOUR (24) MONTH PERIOD IMMEDIATELY PRECEDING THE DATE THAT YOU FIRST FILE A LAWSUIT, ARBITRATION OR ANY OTHER LEGAL
        PROCEEDING AGAINST SHAREMY INC, WHETHER STATUTORY, IN LAW OR IN EQUITY, IN ANY TRIBUNAL. THE DAMAGES LIMITATION SET FORTH IN THE IMMEDIATELY
        PRECEDING SENTENCE APPLIES (I) REGARDLESS OF THE GROUND UPON WHICH LIABILITY IS BASED (WHETHER DEFAULT, CONTRACT, TORT, STATUTE, OR
        OTHERWISE), (II) IRRESPECTIVE OF THE TYPE OF BREACH OF RIGHTS, PRIVILEGES, OR OBLIGATIONS, AND (III) WITH RESPECT TO ALL EVENTS, THE SERVICE,
        AND THIS AGREEMENT.
      </p>
      <p>
        THE LIMITATION OF LIABILITY PROVISIONS SET FORTH IN THIS SECTION 14 SHALL APPLY EVEN IF YOUR REMEDIES UNDER THIS AGREEMENT FAIL WITH RESPECT
        TO THEIR ESSENTIAL PURPOSE.
      </p>
      <p>
        SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES, SO SOME OR ALL OF THE EXCLUSIONS AND LIMITATIONS IN THIS
        SECTION MAY NOT APPLY TO YOU.
      </p>
      <p>
        <strong>15. DISPUTE RESOLUTION SECTION</strong>
      </p>
      <p>
        <strong>
          <em>
            In the unlikely event that we have a legal dispute, here is how the Parties agree to proceed, except where prohibited by applicable law.
          </em>
        </strong>
      </p>
      <p>
        Any Subsection in this Dispute Resolution Section that is prohibited by law shall not apply to the users residing in that jurisdiction,
        including Subsections 15b, 15c, 15d, and 15e, which shall not apply to users residing within the European Union, European Economic Area, the
        United Kingdom, or Switzerland.
      </p>
      <p>
        <strong>15A. INFORMAL DISPUTE RESOLUTION PROCESS</strong>
      </p>
      <p>
        If you are dissatisfied with our Services for any reason, please contact ShareMy Inc Customer Service first so we can try to resolve your
        concerns without the need of outside assistance. If you choose to pursue a dispute, claim or controversy against ShareMy Inc, these terms will
        apply. For purposes of this Dispute Resolution Process and Arbitration Procedures set forth in Section 15, "ShareMy Inc" shall include our
        affiliates, employees, licensors, and service providers.
      </p>
      <p>
        ShareMy Inc values its relationship with you and appreciates the mutual benefit realized from informally resolving Disputes (as defined
        below). Before formally pursuing a Dispute in arbitration or small claims court, you agree to first send a detailed notice ("Notice") to
        ShareMy Inc Legal, P.O. BOX 267, LAVA HOT SPRINGS ID, USA. If ShareMy Inc has a Dispute with you, ShareMy Inc agrees to first send a Notice to
        you at your most recent email address on file with us, or if no email address is on file, other contact information associated with your
        account. Your Notice must contain all of the following information: (1) your full name; (2) information that enables ShareMy Inc to identify
        your account, including a picture or screenshot of your profile, your address, mobile phone number, email address, and date of birth you used
        to register your account if any; and (3) a detailed description of your Dispute, including the nature and factual basis of your claim(s) and
        the relief you are seeking with a corresponding calculation of your alleged damages (if any). You must personally sign this Notice for it to
        be effective. ShareMy Inc’s Notice must likewise set forth a detailed description of its Dispute, which shall include the nature and factual
        basis of its claim(s) and the relief it is seeking, with a corresponding calculation of our damages (if any). You and ShareMy Inc agree to
        then negotiate in good faith in an effort to resolve the Dispute. As part of these good faith negotiations, if ShareMy Inc requests a
        telephone conference with you to discuss your Dispute, you agree to personally participate, with your attorney if you’re represented by
        counsel. Likewise, if you request a telephone conference to discuss ShareMy Inc’s Dispute with you, ShareMy Inc agrees to have one
        representative participate. This informal process should lead to a resolution of the Dispute. However, if the Dispute is not resolved within
        60 days after receipt of a fully completed Notice and the Parties have not otherwise mutually agreed to an extension of this informal dispute
        resolution time period, you or ShareMy Inc may initiate an arbitration (subject to a Party’s right to elect small claims court as provided
        below).
      </p>
      <p>
        Completion of this informal dispute resolution is a condition precedent to filing any demand for arbitration or small claims court action.
        Failure to do so is a breach of this Agreement. The statute of limitations and any filing fee deadlines will be tolled while you and ShareMy
        Inc engage in this informal dispute resolution process. Unless prohibited by applicable law, the arbitration provider, National Arbitration
        and Mediation ("NAM"), shall not accept or administer any demand for arbitration and shall administratively close any arbitration unless the
        Party bringing such demand for arbitration can certify in writing that the terms and conditions of this informal dispute resolution process
        were fully satisfied. A court of competent jurisdiction shall have authority to enforce this provision and to enjoin any arbitration
        proceeding or small claims court action.
      </p>
      <p>
        <strong>15B. INDIVIDUAL RELIEF: CLASS ACTION AND JURY TRIAL WAIVER</strong>
      </p>
      <p>
        <strong>
          TO THE FULLEST EXTENT ALLOWABLE BY LAW, YOU AND SHAREMY INC EACH WAIVE THE RIGHT TO A JURY TRIAL AND THE RIGHT TO LITIGATE DISPUTES IN COURT
          IN FAVOR OF INDIVIDUAL ARBITRATION (EXCEPT FOR SMALL CLAIMS COURT AS PROVIDED ABOVE). YOU AND SHAREMY INC EACH WAIVE THE RIGHT TO FILE OR
          PARTICIPATE IN A CLASS ACTION AGAINST THE OTHER OR OTHERWISE TO SEEK RELIEF ON A CLASS BASIS, INCLUDING ANY CURRENTLY PENDING ACTIONS
          AGAINST SHAREMY INC. TO THE FULLEST EXTENT ALLOWABLE BY LAW, THERE SHALL BE NO RIGHT OR AUTHORITY FOR ANY CLAIMS TO BE ARBITRATED OR
          LITIGATED ON A CLASS, COLLECTIVE, REPRESENTATIVE, CONSOLIDATED, OR PRIVATE ATTORNEY GENERAL BASIS. THE ARBITRATOR CAN AWARD THE SAME RELIEF
          AVAILABLE IN COURT PROVIDED THAT THE ARBITRATOR MAY ONLY AWARD FINAL RELIEF (INCLUDING INJUNCTIVE OR DECLARATORY RELIEF) IN FAVOR OF THE
          INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO THE EXTENT NECESSARY TO PROVIDE FINAL RELIEF WARRANTED BY THAT INDIVIDUAL PARTY’S CLAIM. THE
          ARBITRATOR MAY NOT AWARD FINAL RELIEF FOR, AGAINST, OR ON BEHALF OF ANYONE WHO IS NOT A PARTY TO THE ARBITRATION ON A CLASS, COLLECTIVE,
          REPRESENTATIVE, OR PRIVATE ATTORNEY GENERAL BASIS. IF A COURT DETERMINES THAT ANY OF THESE PROHIBITIONS IN THIS PARAGRAPH ARE UNENFORCEABLE
          AS TO A PARTICULAR CLAIM OR REQUEST FOR RELIEF (SUCH AS A REQUEST FOR PUBLIC INJUNCTIVE RELIEF), AND ALL APPEALS OF THAT DECISION ARE
          EXHAUSTED OR THE DECISION IS OTHERWISE FINAL, THEN YOU AND SHAREMY INC AGREE THAT THAT PARTICULAR CLAIM OR REQUEST FOR RELIEF SHALL PROCEED
          IN COURT BUT SHALL BE STAYED PENDING INDIVIDUAL ARBITRATION OF THE REMAINING CLAIMS FOR RELIEF THAT YOU HAVE BROUGHT. IF THIS SPECIFIC
          PARAGRAPH IS FOUND TO BE UNENFORCEABLE, THEN THE ENTIRETY OF THIS ARBITRATION PROVISION (EXCEPT FOR THE JURY TRIAL WAIVER AND THE INFORMAL
          DISPUTE RESOLUTION PROCESS) SHALL BE NULL AND VOID. THIS PARAGRAPH IS AN ESSENTIAL PART OF THIS ARBITRATION AGREEMENT.
        </strong>
      </p>
      <p>
        <strong>15C. DISPUTE RESOLUTION THROUGH ARBITRATION OR SMALL CLAIMS COURT</strong>
      </p>
      <p>
        Any dispute, claim, or controversy between you and ShareMy Inc (that is not resolved informally by ShareMy Inc Customer Service or as provided
        under subsection 15a above) that arises from or relates in any way to this Agreement (including any alleged breach of this Agreement), the
        Services, or our relationship with you (collectively, "Dispute"), shall be exclusively resolved through BINDING INDIVIDUAL ARBITRATION except
        as specifically provided otherwise in this Dispute Resolution Section. "Dispute" as used in this Agreement shall have the broadest possible
        meaning and include claims that arose before the existence of this or any prior Agreement and claims that arise during the term of this
        Agreement or after the termination of this Agreement. Notwithstanding the foregoing, either you or ShareMy Inc may elect to have an individual
        claim heard in small claims court. If the request to proceed in small claims court is made after an arbitration has been initiated but before
        an arbitrator has been appointed, such arbitration shall be administratively closed. Any controversy over the small claims court’s
        jurisdiction shall be determined by the small claims court. All other issues (except as otherwise provided herein) are exclusively for the
        Arbitrator to decide, including but not limited to scope and enforceability of this Dispute Resolution Section, as well as any request to
        proceed in small claims court that is made after an arbitrator has been appointed. If you or ShareMy Inc challenges the small claims court
        election in your Dispute, and a court of competent jurisdiction determines that the small claims court election is unenforceable, then such
        election shall be severed from this Agreement as to your Dispute. However, such court determination shall not be considered or deemed binding
        with respect to ShareMy Inc’s other contracting parties.
      </p>
      <p>
        Any court proceeding to enforce this Dispute Resolution Section 15, including any proceeding to confirm, modify, or vacate an arbitration
        award, must be commenced in accordance with Section 17. In the event Dispute Resolution Section 15 is for any reason held to be unenforceable,
        any litigation against ShareMy Inc (except for small claims court actions) may be commenced only in the federal or state courts located in
        Bannock County, Idaho USA. You hereby irrevocably consent to those courts’ exercise of personal jurisdiction over you for such purposes and
        waive any claim that such courts constitute an inconvenient forum.
      </p>
      <p>
        <strong>15D. INDIVIDUAL ARBITRATION AND MASS ARBITRATION PROTOCOLS</strong>
      </p>
      <p>
        This subsection 15d applies to Disputes that are submitted to NAM after fully completing the informal Notice and Dispute resolution process
        described in subsection 15a above and when no small claims court election is made by either Party. Any arbitration between you and ShareMy Inc
        shall be administered by NAM in accordance with NAM’s operative Comprehensive Dispute Resolution Rules and Procedures (the "NAM Rules") in
        effect at the time any demand for arbitration is filed with NAM, as modified by this Dispute Resolution Section 15. For a copy of the NAM
        Rules, please visit&#xa0;
        <a href="https://www.namadr.com/resources/rules-fees-forms">
          <span>https://www.namadr.com/resources/rules-fees-forms</span>
        </a>
        &#xa0;or contact NAM at NAM’s National Processing Center at 990 Stewart Avenue, 1st Floor, Garden City, NY 11530 and email address{" "}
        <Link to="/cdn-cgi/l/email-protection" class="__cf_email__" data-cfemail="fb989496969e8998929a97bb959a969a9f89d5989496">
          [email&#160;protected]
        </Link>
        . If NAM is unable or unwilling to perform its duties under this Agreement, the parties shall mutually agree on an alternative administrator
        that will replace NAM and assume NAM’s role consistent with this Agreement. If the parties are unable to agree, they will petition a court of
        competent jurisdiction to appoint an administrator that will assume NAM’s duties under this Agreement.
      </p>
      <p>The Parties agree that the following procedures will apply to any Arbitrations initiated under this Dispute Resolution Section:</p>
      <ol>
        <li>
          <u>Commencing an Arbitration</u>
        </li>
      </ol>
      <p>
        &#xa0;– To initiate an arbitration, you or ShareMy Inc shall send to NAM a demand for arbitration ("Demand for Arbitration") that describes
        the claim(s) and request for relief in detail, consistent with the requirements in this Agreement and NAM Rules. If you send a Demand for
        Arbitration, you shall also send it to ShareMy Inc at ShareMy Inc Legal, P.O. Box 267, Lava Hot Springs ID, USA, within 10 days of delivery of
        the Demand for Arbitration to NAM. If ShareMy Inc sends a Demand for Arbitration, we will also send it to your mailing address on file with us
        within the same 10-day period. If your mailing address is unavailable, we will send it to your email address on file, or if no email address
        is on file, other contact information associated with your account. The arbitration provider shall not accept or administer any demand for
        arbitration and shall administratively close any such demand for arbitration that fails to certify in writing that the Party meets the
        requirements of Dispute Resolution Section 15 or if either Party elects small claims court as set forth above.
      </p>
      <ol start="2">
        <li>
          <u>Fees</u>
        </li>
      </ol>
      <p>
        &#xa0;– The payment of all fees shall be governed by the NAM Rules, except to the extent that the case is a part of a Mass Filing (as defined
        below) or the NAM fees and costs (including Arbitrator fees) paid by either Party are reallocated upon order of the Arbitrator following a
        determination that (a) either Party breached Section 15 of this Agreement, (b) such reallocation is called for under this Agreement, or (c)
        reallocation is otherwise permitted under applicable law. Upon a showing to ShareMy Inc of your financial hardship we will consider a good
        faith request made by you to pay your portion of the applicable consumer portion of the filing fee. ShareMy Inc is committed to ensuring that
        arbitration costs to consumers do not serve as a barrier to the adjudication of disputes. If ShareMy Inc initiates an arbitration against you,
        we shall pay all fees.
      </p>
      <ol start="3">
        <li>
          <u>The Arbitrator</u>
        </li>
      </ol>
      <p>
        &#xa0;– The arbitration shall be conducted by a single, neutral (the "Claim Arbitrator"), as assisted by any Process Arbitrator appointed
        under NAM Rules. (The term "Arbitrator" applies to both the Claim Arbitrator and the Process Arbitrator). If a hearing is elected by either
        Party, the Arbitrator shall be in or close to the location in which you reside. The Arbitrator is bound by and shall adhere to this Agreement.
        In the event NAM Rules conflict with this Agreement, the terms of this Agreement shall control. If the Arbitrator determines that strict
        application of any term of Section 15 of this Agreement (except for the small claims election, which shall be determined by the small claims
        court) would result in a fundamentally unfair arbitration (the "Unfair Term"), then the Arbitrator shall have authority to modify the Unfair
        Term to the extent necessary to ensure a fundamentally fair arbitration that is consistent with the Terms of Use (the "Modified Term"). In
        determining the substance of a Modified Term, the Arbitrator shall select a term that comes closest to expressing the intention of the Unfair
        Term.
      </p>
      <ol start="4">
        <li>
          <u>Dispositive Motions</u>
        </li>
      </ol>
      <p>
        &#xa0;– The Parties agree that the Claim Arbitrator shall have the authority to consider dispositive motions without an oral evidentiary
        hearing. Dispositive motions may be requested under the following circumstances: (a) within 30 days after the Claim Arbitrator’s appointment,
        a Party may request to file a dispositive motion based upon the pleadings; and (b) no later than 30 days prior to the evidentiary hearing, a
        Party may request to file a dispositive motion for summary judgment based upon the Parties’ pleadings and the evidence submitted.
      </p>
      <ol start="5">
        <li>
          <u>Discovery</u>
        </li>
      </ol>
      <p>
        &#xa0;–Each Party may (a) serve up to five requests for relevant, non-privileged documents from the other Party; and (b) request that the
        other Party provide verified responses to no more than 5 relevant interrogatories (including subparts). Unless both Parties agree otherwise,
        no other forms of discovery (including depositions) may be utilized. Any such discovery requests must be served on the other Party within 21
        days after the Claim Arbitrator’s appointment. The responding Party shall provide the requesting Party with all responsive, non-privileged
        documents, responses signed by the Party themselves to the requested interrogatories, and/or any objections to the requests within 30 days
        after receipt of the requests, or, in the event of an objection to any discovery request, 30 days after the Claim Arbitrator resolves the
        dispute. In the event either Party requests that the Claim Arbitrator consider a dispositive motion on the pleadings, such written discovery
        response deadlines shall be extended until 30 days following the Claim Arbitrator’s final decision on such dispositive motion. Any disputes
        about discovery or requests for extensions shall be submitted promptly to the Claim Arbitrator for resolution. In ruling on any discovery
        dispute or extension request, the Claim Arbitrator shall take into consideration the nature, amount, and scope of the underlying arbitration
        claim, the cost and other effort that would be involved in providing the requested discovery, the case schedule, and whether the requested
        discovery is necessary for the adequate preparation of a claim or defense.
      </p>
      <ol start="6">
        <li>
          <u>Confidentiality</u>
        </li>
      </ol>
      <p>
        &#xa0;– Upon either Party’s request, the Arbitrator will issue an order requiring that confidential information of either Party disclosed
        during the arbitration (whether in documents or orally) may not be used or disclosed except in connection with the arbitration or a proceeding
        to enforce the arbitration award and that any permitted court filing of confidential information must be done under seal.
      </p>
      <ol start="7">
        <li>
          <u>Arbitration Hearing</u>
        </li>
      </ol>
      <p>
        &#xa0;– You and ShareMy Inc are entitled to a fair evidentiary hearing (i.e. trial) before the Claim Arbitrator. Arbitration proceedings are
        usually simpler, less costly, and more streamlined than trials and other judicial proceedings. The Parties agree to waive all oral hearings
        and instead submit all disputes to the Claim Arbitrator for an award based on written submissions and other evidence as the Parties may agree,
        unless a Party requests an oral hearing within 10 days after the Respondent files a response. If an oral evidentiary hearing is requested,
        both Parties must be personally present at the hearing, regardless of whether either Party has retained counsel. Both Parties must personally
        attend the hearing. Either Party’s failure to personally attend the hearing, without a continuance ordered by the Claim Arbitrator for good
        cause, will result in a default judgment taken against that Party.
      </p>
      <ol start="8">
        <li>
          <u>Arbitration Award</u>
        </li>
      </ol>
      <p>
        &#xa0;– Regardless of the format of the arbitration, the Claim Arbitrator shall provide a reasoned decision, in writing within 30 days after
        the hearing or, if no hearing is held, within 30 days after any rebuttal or supplemental statements are due. The decision must clearly specify
        the relief, if any, awarded and contain a brief statement of the reasons for the award. The arbitration award is binding only between you and
        ShareMy Inc and will not have any preclusive effect in another arbitration or proceeding that involves a different Party. The Claim Arbitrator
        may, however, choose to consider rulings from other arbitrations involving a different Party. The Arbitrator may award fees and costs as
        provided by the NAM Rules or to the extent such fees and costs could be awarded in court. This includes but is not limited to the ability of
        the Arbitrator to award fees and costs if the Arbitrator determines that a claim or defense is frivolous or was brought for an improper
        purpose, for the purpose of harassment, or in bad faith.
      </p>
      <ol start="9">
        <li>
          <u>Offer of Settlement</u>
        </li>
      </ol>
      <p>
        &#xa0;– The Respondent may, but is not obligated to, make a written settlement offer to the opposing Party any time before the evidentiary
        hearing or, if a dispositive motion is permitted, prior to the dispositive motion being granted. The amount or terms of any settlement offer
        may not be disclosed to the Claim Arbitrator until after the Claim Arbitrator issues an award on the claim. If the award is issued in the
        opposing Party’s favor and is less than the Respondent’s settlement offer or if the award is in the Respondent’s favor, the opposing Party
        must pay the Respondent’s costs incurred after the offer was made, including any attorney’s fees. If any applicable statute or caselaw
        prohibits the flipping of costs incurred in the arbitration, then the offer in this provision shall serve to cease the accumulation of any
        costs that claimant may be entitled to for the cause of action under which it is suing.
      </p>
      <ol start="10">
        <li>
          <u>Mass Filing</u>
        </li>
      </ol>
      <p>
        &#xa0;– If, at any time, 25 or more similar demands for arbitration are asserted against ShareMy Inc or related parties by the same or
        coordinated counsel or entities ("Mass Filing"), consistent with the definition and criteria of Mass Filings set forth in the NAM’s Mass
        Filing Supplemental Dispute Resolution Rules and Procedures ("NAM’s Mass Filing Rules", available at&#xa0;
        <a href="https://www.namadr.com/resources/rules-fees-forms/">
          <span>https://www.namadr.com/resources/rules-fees-forms/</span>
        </a>
        "), the additional protocols set forth below shall apply.
      </p>
      <ol>
        <li>
          If you or your counsel file a Demand for Arbitration that fits within the definition of Mass Filing referred to above, then you agree that
          your Demand for Arbitration shall be subject to the additional protocols set forth in this Mass Filing subsection. You also acknowledge that
          the adjudication of your Dispute might be delayed and that any applicable statute of limitations shall be tolled from the time at which the
          first cases are chosen to proceed until your case is chosen for a bellwether proceeding.
        </li>
      </ol>
      <ol start="2">
        <li>
          NAM’s Mass Filing Rules shall apply if your Dispute is deemed by NAM, in its sole discretion pursuant to its Rules and this Dispute
          Resolution Section, to be part of a Mass Filing. Such election for NAM’s Mass Filing Rules and related fee schedule must be made by either
          you or ShareMy Inc in writing and submitted to NAM and all Parties.
        </li>
      </ol>
      <ol start="3">
        <li>
          <strong>
            <em>Bellwether Proceedings</em>
          </strong>
          <strong>
            . Bellwether proceedings are encouraged by courts and arbitration administrators when there are multiple disputes involving similar claims
            against the same or related parties. Counsel for the Mass Filings claimants (including you) and counsel for ShareMy Inc shall each select
            15 Demands for Arbitration (30 total), and no more than 30 arbitrations shall be filed, processed, adjudicated, or pending at the same
            time, with each of the 30 individual arbitrations presided over by a different Claim Arbitrator, in a first set of bellwether proceedings.
            During this time, no other Demands for arbitration that are part of the Mass Filings may be filed, processed, adjudicated, or pending. If
            the Parties are unable to resolve the remaining Demands for Arbitration after the first set of bellwether proceedings are arbitrated or
            otherwise resolved, then counsel for the Claimants and counsel for ShareMy Inc shall each select an additional 15 Demands for Arbitration
            (30) total to be filed, processed, and adjudicated as individual arbitrations, with each of the 30 arbitrations presided over by a
            different Claim Arbitrator, in a second set of bellwether proceedings. During this time, no other Demands for Arbitration that are part of
            the Mass Filings may be filed, processed, or adjudicated. This staged process of bellwether proceedings, with each set including 30
            Demands for Arbitration adjudicated on an individual basis, shall continue until each Demand included in the Mass Filings (including your
            Demand for Arbitration) is adjudicated or otherwise resolved. Fees associated with a Demand for Arbitration included in the Mass Filings,
            including fees owed by ShareMy Inc and the claimants (including you), shall only be due after your Demand for Arbitration is chosen as
            part of a set of bellwether proceedings and therefore properly designated for filing, processing, and adjudication. Any applicable statute
            of limitations shall be tolled beginning when you initiate the informal dispute resolution process set forth in subsection 15a of the
            Agreement, and if the first Mass Filings’ Demands for Arbitration are chosen for the initial set of bellwether proceedings have been
            filed, your claims will remain tolled until your Demand for Arbitration is decided, withdrawn, or is settled. A court of competent
            jurisdiction located in a venue allowed under Section 17 of the Agreement shall have the power to enforce this subsection.
          </strong>
        </li>
      </ol>
      <ol start="4">
        <li>
          You and ShareMy Inc agree that we each value the integrity and efficiency of the arbitration and small claims court process and wish to
          employ the process for the fair resolution of genuine and sincere disputes between us. You and ShareMy Inc acknowledge and agree to act in
          good faith to ensure the fair resolution of genuine and sincere Disputes. The Parties further agree that application of these Mass Filings
          procedures have been reasonably designed to result in an efficient and fair adjudication of such cases.
        </li>
      </ol>
      <p>
        <strong>15E. FUTURE CHANGES AND RETROACTIVE APPLICATION</strong>
      </p>
      <p>
        This Dispute Resolution Section 15 applies to all Disputes between the Parties, including for any claims that accrued against you or ShareMy
        Inc prior to the time of your consent to this Agreement and to any claims that accrue against you or ShareMy Inc after your consent to this
        Agreement. Notwithstanding any provision in this Agreement to the contrary, you may elect to opt out of the retroactive application of this
        Dispute Resolution Section 15 as to claims that have accrued against you or against ShareMy Inc prior to the time of your consent to this
        Agreement. You may opt out by sending us written notice, within 30 days of the time you consent to this Agreement, to the following email
        address:&#xa0;
        <Link to="/cdn-cgi/l/email-protection#44273137302b292136372136322d272104172c253621093d0d2a276a272b29">
          <span>
            <span class="__cf_email__" data-cfemail="d1b2a4a2a5bebcb4a3a2b4a3a7b8b2b49182b9b0a3b49ca898bfb2ffb2bebc">
              [email&#160;protected]
            </span>
          </span>
        </Link>
        . You must include information sufficient to identify your account(s), such as the email address or phone number associated with your
        account(s), and should include a statement that you are opting out of the retroactive application of this Dispute Resolution Section 15.
        Please note: if you opt out of the retroactive application of this Dispute Resolution Section 15, you will still be subject to and bound by
        any Dispute Resolution Sections and Arbitration Procedures you previously agreed to, including any arbitration provisions, class action
        waivers, and retroactive application sections. Also, regardless of whether you opt out of the retroactive application of these changes, the
        Parties will resolve any claims that accrue against you or ShareMy Inc after your consent to this Agreement in accordance with this Dispute
        Resolution Section.
      </p>
      <p>
        <strong>16. GOVERNING LAW</strong>
      </p>
      <p>
        <strong>
          <em>Idaho law and the Federal Arbitration Act will apply to any Dispute (except where prohibited by law).</em>
        </strong>
      </p>
      <p>
        To the fullest extent allowable by law, the laws of Idaho, U.S.A., without regard to its conflict of laws rules, shall apply to any Dispute
        arising out of or relating to this Agreement or our Services. Notwithstanding the foregoing, the Dispute Resolution Process set forth in
        Section 15 shall be governed by the Federal Arbitration Act.
      </p>
      <p>
        <strong>17. VENUE/FORUM SELECTION</strong>
      </p>
      <p>
        <strong>
          <em>
            To the fullest extent allowable by law, any claims that are not arbitrated for any reason must be litigated in Bannock County, Idaho USA
            (except for claims filed in small claims court).
          </em>
        </strong>
      </p>
      <p>
        Except where prohibited by law and except for claims that are heard in a small claims court as set forth in Section 15, any claims arising out
        of or relating to this Agreement, to our Services, or to your relationship with ShareMy Inc that for whatever reason are not required to be
        arbitrated or filed in small claims court, will be litigated exclusively in the federal or state courts located in Bannock County, Idaho,
        U.S.A. You and ShareMy Inc consent to the exercise of personal jurisdiction of courts in the State of Idaho and waive any claim that such
        courts constitute an inconvenient forum.
      </p>
      <p>
        <strong>18. INDEMNITY BY YOU</strong>
      </p>
      <p>
        <strong>
          <em>You agree to indemnify ShareMy Inc if a claim is made against ShareMy Inc due to your actions.</em>
        </strong>
      </p>
      <p>
        You agree, to the extent permitted under applicable law, to indemnify, defend, and hold harmless ShareMy Inc, our affiliates, and their and
        our respective officers, directors, agents, and employees from and against any and all complaints, demands, claims, damages, losses, costs,
        liabilities, and expenses, including attorney’s fees, due to, arising out of, or relating in any way to your access to or use of our Services,
        your Content, your conduct toward other users, or your breach of this Agreement.
      </p>
      <p>
        <strong>19. ACCEPTANCE OF TERMS</strong>
      </p>
      <p>
        <strong>
          <em>By using our Services, you accept the Terms of this Agreement.</em>
        </strong>
      </p>
      <p>
        By using our Services, whether through a mobile device, mobile application, or computer, you agree to be bound by (i) these Terms, which we
        may amend from time to time, (ii) our&#xa0;
        <Link to="/privacy-policy">
          <span>Privacy Policy</span>
        </Link>
        ,&#xa0;
        <Link to="/cookie-policy">
          <span>Cookie Policy</span>
        </Link>
        ,&#xa0;
        <Link to="/community-guide">
          <span>Community Guidelines</span>
        </Link>
        , and&#xa0;
        <Link to="/safety-guide">
          <span>Safety Guidelines</span>
        </Link>
        , and (iii) any Additional Terms Upon Purchase. If you do not accept and agree to be bound by all of the terms of this Agreement, you are not
        entitled to use our Services.
      </p>
      <p>
        All pronouns and any variations thereof shall be deemed to refer to the masculine, feminine, neuter, singular or plural as the identity of the
        entities or persons referred to any require.
      </p>
      <p>
        <strong>20. ENTIRE AGREEMENT</strong>
      </p>
      <p>
        <strong>
          <em>This Agreement supersedes any previous agreements or representations.</em>
        </strong>
      </p>
      <p>
        These Terms, with the&#xa0;
        <Link to="/privacy-policy">
          <span>Privacy Policy</span>
        </Link>
        ,&#xa0;
        <Link to="/cookie-policy">
          <span>Cookie Policy</span>
        </Link>
        ,&#xa0;
        <Link to="/community-guide">
          <span>Community Guidelines</span>
        </Link>
        , and&#xa0;
        <Link to="/safety-guide">
          <span>Safety </span>
        </Link>
        <span>Guidelines</span>, and any Additional Terms Upon Purchase, contain the entire agreement between you and ShareMy Inc regarding the use of
        our Services. The Terms supersede all previous agreements, representations, and arrangements between us, written or oral. If any provision of
        these Terms is held invalid, illegal, or otherwise unenforceable, the remainder of the Terms shall continue in full force and effect. The
        failure of the Company to exercise or enforce any right or provision of these Terms shall not constitute a waiver of such right or provision.
        You agree that your ShareMy Inc account is non-transferable and all of your rights to your account and its content terminate upon your death,
        unless otherwise provided by law. Any rights and licenses granted hereunder may not be transferred or assigned by you but may be assigned by
        us without restriction. No agency, partnership, joint venture, fiduciary or other special relationship or employment is created as a result of
        these Terms, and you may not make any representations on behalf of or bind ShareMy Inc in any manner.
      </p>
      <p>
        <strong>21. SPECIAL STATE TERMS</strong>
      </p>
      <p>
        <strong>
          <em>
            Special terms apply in Arizona, California, Colorado, Connecticut, Illinois, Iowa, Minnesota, New York, North Carolina, Ohio, Rhode
            Island, and Wisconsin
          </em>
        </strong>
      </p>
      <p>
        <u>For subscribers residing in New York:</u>
      </p>
      <ul>
        <li>
          <span>
            The Services do not guarantee any number of "referrals"—rather, the functionality of the Services is such that the subscriber can view as
            many profiles as he/she would like;
          </span>
        </li>
      </ul>
      <ul>
        <li>
          <span>
            Upon notice in writing and delivered to ShareMy Inc Legal, P.O. Box 267, Lava Hot Springs ID, USA, subscribers may place their
            subscription on hold for up to one year;
          </span>
        </li>
      </ul>
      <ul>
        <li>
          <span>How your information is used and how you may access your information is set forth in our</span>
          <span>&#xa0;</span>
          <Link to="/privacy-policy">
            <span>Privacy Policy</span>
          </Link>
          <span>;</span>
        </li>
      </ul>
      <p>
        <u>For subscribers residing in Illinois, New York, North Carolina, and Ohio :</u>
      </p>
      <ul>
        <li>
          <span>
            Our Services are widely available in the United States—if you believe that you have moved outside a location where we provide the
            Services, please contact us in writing delivered to ShareMy Inc Legal, P.O. Box 267, Lava Hot Springs ID, USA, and we will work with you
            to provide alternative services or a refund.
          </span>
        </li>
      </ul>
      <p>
        <u>
          For subscribers residing in Arizona, California, Colorado, Connecticut, Illinois, Iowa, Minnesota, New York, North Carolina, Ohio, Rhode
          Island, and Wisconsin:
        </u>
      </p>
      <p>
        Your Right to Cancel—You may cancel your subscription, without penalty or obligation, at any time prior to midnight of the third business day
        following the date you subscribed. In the event that you die before the end of your subscription period, your estate shall be entitled to a
        refund of that portion of any payment you had made for your subscription which is allocable to the period after your death. In the event that
        you become disabled (such that you are unable to use our Services) before the end of your subscription period, you shall be entitled to a
        refund of that portion of any payment you had made for your subscription which is allocable to the period after your disability by providing
        the Company notice in the same manner as you request a refund as described above in Section 8.
      </p>
    </div>
  );
};

export default TermsOfUse;
