import React from "react";
import { Link } from "react-router-dom";

const CommunityGuide = () => {
  return (
    <div className="content">
      <h2>
        <Link to="/">Home</Link> / Community Guidelines
      </h2>
      <br />

      <p>
        <b>ShareMy Community Guidelines</b>
      </p>
      <p>
        Welcome to the ShareMy community. We honor and welcome you here. We created the ShareMy Community Guidelines to help protect this amazing
        incredible community. By using ShareMy, you agree to these guidelines and our
        <a
          href="https://l.facebook.com/l.php?u=https%3A%2F%2Fwww.instagram.com%2Flegal%2Fterms%3Ffbclid%3DIwAR2BCHm_6NZ7-HXSx-wA4atH9pXVIlBJA0AmmtnhR-GlA64406aM0Kv8b7o&amp;h=AT0oIcntM1QbNtQY8jkscPkwBeyP0bTv_JKV2jdiLM_sUOJKIvBPjoGc378kbBF7fofqwRB1lXQ95bNV8xTU5Kq4N6Md3DsHFMNXe50vbC8rOE2HEgLAc4lQvvZR2Pu1hpLRLQ"
          target="_blank"
        >
          Terms of Use
        </a>
        . We’re committed to these guidelines and we hope you will be too. We invite you to add to our community in wonderful and positive ways.
        Overstepping these guidelines may result in deleted content,{" "}
        <a
          href="https://l.facebook.com/l.php?u=https%3A%2F%2Fhelp.instagram.com%2F366993040048856%3Fhelpref%3Dfaq_content%26fbclid%3DIwAR042ZuSvjP31ep01gFLSrvVOpjDN1rIv5KUUkrZFT5njVQ282lsmPZGrTU&amp;h=AT0oIcntM1QbNtQY8jkscPkwBeyP0bTv_JKV2jdiLM_sUOJKIvBPjoGc378kbBF7fofqwRB1lXQ95bNV8xTU5Kq4N6Md3DsHFMNXe50vbC8rOE2HEgLAc4lQvvZR2Pu1hpLRLQ"
          target="_blank"
        >
          <span>disabled accounts</span>
        </a>
        , or other restrictions.
      </p>
      <p>
        If you are kind and respectful to others then this is the place for you, but if you choose not to be, you won’t be here for long. We try to
        allow users to express themselves honestly and freely as long as it doesn’t offend others. We strive to hold everyone to the same standard on
        ShareMy. That standard includes you being considerate and thinking of others so that everyone on ShareMy can have a positive experience. We
        require every user to abide by our community guidelines both on and offline. Your behavior on and offline can lead to termination of your
        ShareMy account.
      </p>
      <p>
        Below is a list of our community guidelines. If you violate any of these guidelines, you could be banned from ShareMy and we give no second
        chances.
      </p>
      <p>
        We encourage you to report any behavior that violates our policies at safety@ShareMyInc.com, and also read our&nbsp;
        <a href="https://www.match.com/help/safetytips">Safety Guidelines</a>.
      </p>
      <p>
        <b>Nudity and/or Sexual Content</b>
      </p>
      <p>No nudity or sexually explicit content. This includes photos, videos, and digitally-created content that shows nudity. Keep it clean.</p>
      <p>
        <b>Harassment</b>
      </p>
      <p>
        Do not engage, promote or encourage others to engage, in any abuse or harassment against any other user. This includes sending any
        threatening, hateful or sexual content to other users. Reports of stalking, threats, bullying or intimidation will be cause for your account
        to be terminated.
      </p>
      <p>
        <b>Violence and Physical Harm</b>
      </p>
      <p>
        We do not tolerate violent, graphic or any actions or content that advocate for or threaten violence of any sort, including threatening or
        promoting terrorism. Physical assault, coercion and any acts of violence are&nbsp;<b>strictly prohibited</b>.
      </p>
      <p>
        Content that advocates for or glorifies suicide or self-harm is also not allowed. We try to do our part by providing information so people can
        get the help they need.
      </p>
      <p>
        <b>Hate Speech</b>
      </p>
      <p>
        It's never OK to encourage or post any content that promotes, advocates for or condones racism, bigotry, hatred, or violence against
        individuals or groups based on factors like (but not limited to) race, ethnicity, religious affiliation, disability, gender, age, national
        origin, sexual orientation, gender identity or pollical affiliation. Any threats of violence or attack on anyone based on their race,
        ethnicity, national origin, sex, gender, gender identity, sexual orientation, religious affiliation, disabilities, or diseases is not
        acceptable in the ShareMy community. This includes specific threats of physical harm as well as threats of theft, vandalism, and other
        financial harm.
      </p>
      <p>
        <b>Private Information</b>
      </p>
      <p>
        Don’t publicly broadcast any private information, whether yours or anyone else’s. This includes social security numbers, passports, passwords,
        birthdates, financial information or unlisted contact information, such as phone numbers, email addresses and home/work addresses.
      </p>
      <p>
        <b>Spam</b>
      </p>
      <p>
        Don’t be fake. Be real instead. Don’t use ShareMy to drive people to external websites via a link or otherwise. Help us stay spam free by not
        artificially collecting likes, followers, and/or posting repetitive comments or content, or repeatedly contacting people for commercial
        purposes without their consent.
      </p>
      <p>
        <b>Create meaningful and genuine interactions</b>
      </p>
      <p>
        Make real friends with real honesty. Don’t post content that engages in, promotes, encourages, facilitates, or admits to the offering,
        solicitation or trade of{" "}
        <a href="https://www.facebook.com/communitystandards/fraud_deception">
          <span>fake and misleading user reviews or ratings</span>
        </a>
        .
      </p>
      <p>
        You don’t have to use your real name on ShareMy, but we do require users to provide us with accurate and up to date information for
        establishing and maintaining an account. Don't{" "}
        <span>
          <a href="https://www.facebook.com/communitystandards/misrepresentation">impersonate</a>
        </span>
        others and don't create accounts for the purpose of violating our guidelines or{" "}
        <a
          href="https://l.facebook.com/l.php?u=https%3A%2F%2Ftransparency.fb.com%2Fpolicies%2Fcommunity-standards%2Finauthentic-behavior%2F&amp;h=AT0oIcntM1QbNtQY8jkscPkwBeyP0bTv_JKV2jdiLM_sUOJKIvBPjoGc378kbBF7fofqwRB1lXQ95bNV8xTU5Kq4N6Md3DsHFMNXe50vbC8rOE2HEgLAc4lQvvZR2Pu1hpLRLQ"
          target="_blank"
        >
          <span>misleading others</span>
        </a>
        .
      </p>
      <p>
        <b>Follow the Laws and Regulations of the Land</b>
      </p>
      <p>
        Do not use ShareMy to do anything that is illegal. Do not use ShareMy to support or praise{" "}
        <a href="https://www.facebook.com/communitystandards/dangerous_individuals_organizations">
          <span>terrorism, organized crime, or hate groups</span>
        </a>
        . Offering
        <a href="https://www.facebook.com/communitystandards/sexual_solicitation">
          <span>sexual services</span>
        </a>
        , buying or selling firearms, alcohol, and tobacco products between private individuals, and buying or selling non-medical or pharmaceutical
        drugs are also not allowed. Remember to always follow the law when offering to sell or buy other
        <a href="https://www.facebook.com/communitystandards/regulated_goods">
          <span>regulated goods</span>
        </a>
        .
      </p>
      <p>
        We have absolutely no tolerance when it comes to sharing{" "}
        <span>
          <a href="https://www.facebook.com/communitystandards/child_nudity_sexual_exploitation">sexual content involving minors</a>{" "}
        </span>
        or threatening to post{" "}
        <span>
          <a href="https://www.facebook.com/communitystandards/sexual_exploitation_adults">intimate images</a>{" "}
        </span>
        of others.
      </p>
      <p>
        <b>Promotion or Solicitation</b>
      </p>
      <p>
        Soliciting other users is prohibited on ShareMy. Do not use ShareMy to promote your profile and/or activity if the purpose is to advertise
        your business, non-profit, political campaign, contest or to conduct research or any other commercial activity except where this is expressly
        and specifically allowed on the ShareMy commercial platform for this purpose only.
      </p>
      <p>
        <b>Scamming</b>
      </p>
      <p>
        ShareMy has a zero-tolerance policy toward anyone attempting to get other users’ private information for fraudulent or illegal activity. Any
        user caught sharing their own financial account information (PayPal, Venmo, etc.) for the purpose of receiving money from other users may also
        be banned from ShareMy.
      </p>
      <p>
        <b>Minors</b>
      </p>
      <p>You must be 18 years of age or older to use ShareMy.</p>
      <p>
        <b>Copyright and Trademark Infringement</b>
      </p>
      <p>Do not display any work that is copyrighted or trademarked by others unless you are allowed to do so.</p>
      <p>
        <b>One Person, One Account</b>
      </p>
      <p>Do not create an account with multiple owners such as with a friend. Additionally, do not maintain multiple ShareMy accounts.</p>
      <p>
        <b>Account Dormancy</b>
      </p>
      <p>
        We encourage you to use your ShareMy account as often and as much as you like, but if you don’t log in to your ShareMy account for 2 years, we
        may delete your account for inactivity.
      </p>
    </div>
  );
};

export default CommunityGuide;
