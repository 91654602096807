import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom"; /* CHANGE TO HashRouter if deployed on static server*/
import CaliforniaPolicy from "../components/CaliforniaPolicy";
import CookiePolicy from "../components/CookiePolicy";
import CommunityGuide from "../components/CommunityGuide";
import SafetyGuide from "../components/SafetyGuide";
import TermsOfUse from "../components/TermsOfUse";
import PrivacyPolicy from "../components/PrivacyPolicy";
import Layout from "../components/Layout";
import Homepage from "../components/Homepage";

const MyRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Homepage />} />
          <Route path="california-policy" element={<CaliforniaPolicy />} />
          <Route path="cookie-policy" element={<CookiePolicy />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="community-guide" element={<CommunityGuide />} />
          <Route path="safety-guide" element={<SafetyGuide />} />
          <Route path="terms-of-use" element={<TermsOfUse />} />
          <Route path="*" element={<Navigate replace to="/" />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default MyRouter;
