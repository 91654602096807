import React from "react";
import { Link } from "react-router-dom";

const CaliforniaPolicy = () => {
  return (
    <div className="content">
      <h2>
        <Link to="/">Home</Link> / California Privacy Notice
      </h2>
      <br />
      <p>
        <b>CCPA Privacy Notice Addendum to ShareMy Inc Privacy Policy</b>
      </p>
      <p>
        This California section supplements the Privacy Policy and applies solely to California consumers (excluding our personnel). The Table below
        describes how we process California consumers’ personal information (excluding our personnel), based on definitions laid out in the California
        Consumer Privacy Act, as amended by the California Privacy Rights Act (&quot;CCPA&quot;).
      </p>
      <p>
        <u>
          <b>Not all of the items listed in the Table below are relevant to you. </b>
        </u>
        For example, we do not collect Social Security numbers from our users, but we may collect this data in the course of evaluating a job
        applicant.
      </p>

      <table cellpadding="1" cellspacing="0">
        <col />
        <col />
        <col />
        <col />
        <thead>
          <tr>
            <td>
              <b>Categories of personal information collected over the 12-month period prior to the effective date of our Privacy Policy</b>
              <p>
                <i>Note: The categories of data below stem from a statutory list set forth under the CCPA.</i>
              </p>
            </td>
            <td>
              <p>
                <b>Sources of collection over the 12-month period prior to the effective date of our Privacy Policy</b>
              </p>
            </td>
            <td>
              <p>
                <b>
                  Categories of third parties with whom we disclosed the personal information for a business purpose over the 12-month period prior to
                  the effective date of our Privacy Policy
                </b>
              </p>
            </td>
            <td>
              <p>
                <b>
                  Business or commercial purposes for which the personal information was collected over the 12-month period prior to the effective
                  date of our Privacy Policy (in addition to the purposes listed in the Privacy Policy)
                </b>
              </p>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr valign="top">
            <td>
              <p>
                Identifiers such as a real name, alias, postal address, unique personal identifier (such as a device identifier; cookies, beacons,
                pixel tags, mobile ad identifiers and similar technology; customer number, unique pseudonym, or user alias; telephone number and other
                forms of persistent or probabilistic identifiers), online identifier, Internet Protocol address, email address, account name, Social
                Security number, driver’s license number, passport number, and other similar identifiers
              </p>
            </td>
            <td>
              <ul>
                <li>
                  <p>From you (for instance if you create an account or apply for a position at our company)</p>
                </li>
              </ul>
              <ul>
                <li>
                  <p>From social media networks (for example if you log into our service through an account you have on a social media network)</p>
                </li>
              </ul>
              <ul>
                <li>
                  <p>
                    From partners (for example, where our ads promoting our services are published on a partner's website or where a partner or
                    consumer reporting agency shares with us information about bad actors)
                  </p>
                </li>
              </ul>
              <ul>
                <li>
                  <p>From our affiliated companies (notably for anti-fraud and safety purposes)</p>
                </li>
              </ul>
            </td>
            <td rowspan="3">
              <ul>
                <li>
                  <p>Other users as part of the general operation of the service</p>
                </li>
              </ul>
              <ul>
                <li>
                  <p>Others, upon the instruction of a user using a profile sharing functionality</p>
                </li>
              </ul>
              <ul>
                <li>
                  <p>Our affiliates (in particular to prevent, detect and fight fraud or other illegal or unauthorized activities under our Terms)</p>
                </li>
              </ul>
              <ul>
                <li>
                  <p>Our payment processors and fraud vendors (e.g., for anti-fraud purposes and to fight against other illegal activities)</p>
                </li>
              </ul>
              <ul>
                <li>
                  <p>Joint marketing partners</p>
                </li>
              </ul>
              <ul>
                <li>
                  <p>
                    Other vendors and professional services organizations who assist us in relation to the business or commercial purposes laid out
                    herein
                  </p>
                </li>
              </ul>
            </td>
            <td rowspan="10">
              <ul>
                <li>
                  <p>
                    Performing services, including maintaining or servicing accounts, providing customer service, processing or fulfilling orders and
                    transactions, verifying customer information, processing payments, providing advertising or marketing services, providing
                    analytics services, providing storage, or providing similar services
                  </p>
                </li>
              </ul>
              <ul>
                <li>
                  <p>
                    Auditing related to counting ad impressions to unique visitors, verifying positioning and quality of ad impressions, and auditing
                    compliance
                  </p>
                </li>
              </ul>
              <ul>
                <li>
                  <p>
                    Short-term, transient use, including, but not limited to, the contextual customization of ads shown as part of the same
                    interaction
                  </p>
                </li>
              </ul>
              <ul>
                <li>
                  <p>Helping ensure security and integrity of our services</p>
                </li>
              </ul>
              <ul>
                <li>
                  <p>Debugging to identify and repair errors that impair existing intended functionality</p>
                </li>
              </ul>
              <ul>
                <li>
                  <p>Undertaking internal research for technological development and demonstration</p>
                </li>
              </ul>
              <ul>
                <li>
                  <p>
                    Undertaking activities to verify or maintain the quality or safety of a service or device that is owned, manufactured,
                    manufactured for, or controlled by us, and to improve, upgrade, or enhance the service or device that is owned, manufactured,
                    manufactured for, or controlled by us
                  </p>
                </li>
              </ul>
              <ul>
                <li>
                  <p>Managing our career opportunities</p>
                </li>
              </ul>
              <ul>
                <li>
                  <p>Establishing and managing our relationships with our vendors and consultants</p>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td valign="top">
              <p>
                Signature, physical characteristics or description, state identification card number, education, bank account number, credit card
                number, debit card number, other financial information, and medical information
              </p>
            </td>
            <td valign="top">
              <ul>
                <li>
                  <p>From you (for instance if you create an account or apply for a position at our company)</p>
                </li>
              </ul>
              <ul>
                <li>
                  <p>From our affiliated companies (notably for anti-fraud and safety purposes)</p>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td valign="top">
              <p>
                Characteristics of protected classifications under California or federal law, such as race, color, national origin, ethnicity,
                religion, age, sex, gender, gender identity, gender expression, sexual orientation, marital status, medical condition, disability,
                citizenship status, and military and veteran status
              </p>
            </td>
            <td valign="top">
              <ul>
                <li>
                  <p>From you (for instance if you create an account or apply for a position at our company)</p>
                </li>
              </ul>
              <ul>
                <li>
                  <p>From consumer reporting agencies if they share with us information about bad actors)</p>
                </li>
              </ul>
              <ul>
                <li>
                  <p>From social media networks (for example if you log into our service through an account you have on a social media network)</p>
                </li>
              </ul>
              <ul>
                <li>
                  <p>From our affiliated companies (notably for anti-fraud and safety purposes)</p>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td valign="top">
              <p>
                Commercial information, including records of personal property, products or services purchased, obtained, or considered, and other
                purchasing or consuming histories or tendencies
              </p>
            </td>
            <td valign="top">
              <ul>
                <li>
                  <p>From you (if you purchase something from us)</p>
                </li>
              </ul>
            </td>
            <td valign="top">
              <ul>
                <li>
                  <p>Our payment processors and fraud vendors (e.g., for anti-fraud purposes and to fight against other illegal activities)</p>
                </li>
              </ul>
              <ul>
                <li>
                  <p>
                    Other vendors and professional services organizations who assist us in relation to the business or commercial purposes laid out
                    herein
                  </p>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td valign="top">
              <p>
                Internet and other electronic network activity information, including, but not limited to, browsing history, search history, and
                information regarding your interaction with websites, applications or advertisements
              </p>
            </td>
            <td valign="top">
              <ul>
                <li>
                  <p>From you (for example, logs generated as you use our services)</p>
                </li>
              </ul>
              <ul>
                <li>
                  <p>From users of our services (for instance through other users' interactions with you)</p>
                </li>
              </ul>
              <ul>
                <li>
                  <p>From advertising partners (for example, where our ads promoting our services are published on a partner's website)</p>
                </li>
              </ul>
            </td>
            <td valign="top">
              <ul>
                <li>
                  <p>
                    Vendors and professional services organizations who assist us in relation to the business or commercial purposes laid out herein
                  </p>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td valign="top">
              <p>Geolocation data, including precise geolocation information</p>
            </td>
            <td valign="top">
              <ul>
                <li>
                  <p>From your device (if you consent that we collect and use your geolocation as you use our services)</p>
                </li>
              </ul>
            </td>
            <td valign="top">
              <ul>
                <li>
                  <p>
                    Vendors and professional services organizations who assist us in relation to the business or commercial purposes laid out herein
                  </p>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td valign="top">
              <p>Audio, electronic, visual, and similar information</p>
            </td>
            <td valign="top">
              <ul>
                <li>
                  <p>From you (for instance the photo(s) you upload when you create an account with us)</p>
                </li>
              </ul>
              <ul>
                <li>
                  <p>From social media networks (for example if you upload photos from one of your accounts on a social media network)</p>
                </li>
              </ul>
              <ul>
                <li>
                  <p>From consumer reporting agencies if they share with us information about bad actors</p>
                </li>
              </ul>
              <ul>
                <li>
                  <p>From our affiliated companies (notably for anti-fraud and safety purposes)</p>
                </li>
              </ul>
            </td>
            <td valign="top">
              <ul>
                <li>
                  <p>Other users as part of the general operation of the service</p>
                </li>
              </ul>
              <ul>
                <li>
                  <p>Others, upon the instruction of a user using a profile sharing functionality</p>
                </li>
              </ul>
              <ul>
                <li>
                  <p>Our affiliates (in particular to prevent, detect and fight fraud or other illegal or unauthorized activities under our Terms)</p>
                </li>
              </ul>
              <ul>
                <li>
                  <p>Joint marketing partners</p>
                </li>
              </ul>
              <ul>
                <li>
                  <p>
                    Other vendors and professional services organizations who assist us in relation to the business or commercial purposes laid out
                    herein
                  </p>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td valign="top">
              <p>Professional or employment-related information</p>
            </td>
            <td valign="top">
              <ul>
                <li>
                  <p>
                    From you (for instance if you apply for a position at or provide services to our company or if you say who your employer is in
                    your profile)
                  </p>
                </li>
              </ul>
              <ul>
                <li>
                  <p>
                    From others (for instance from people who may be working with you as you provide services to us or who may participate to the
                    hiring process)
                  </p>
                </li>
              </ul>
            </td>
            <td valign="top">
              <ul>
                <li>
                  <p>Other users as part of the general operation of the service</p>
                </li>
              </ul>
              <ul>
                <li>
                  <p>Others, upon the instruction of a user using a profile sharing functionality</p>
                </li>
              </ul>
              <ul>
                <li>
                  <p>
                    Other vendors and professional services organizations who assist us in relation to the business or commercial purposes laid out
                    herein
                  </p>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td valign="top">
              <p>
                Inferences drawn from any of the information identified above to create a profile about you reflecting your preferences,
                characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.
              </p>
            </td>
            <td valign="top">
              <ul>
                <li>
                  <p>Created from data in your profile</p>
                </li>
              </ul>
            </td>
            <td valign="top">
              <ul>
                <li>
                  <p>
                    Vendors and professional services organizations who assist us in relation to the business or commercial purposes laid out herein
                  </p>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td valign="top">
              <p>Contents of your messages on our services</p>
            </td>
            <td valign="top">
              <ul>
                <li>
                  <p>From you (when you use the message features on our services)</p>
                </li>
              </ul>
            </td>
            <td valign="top">
              <ul>
                <li>
                  <p>
                    Vendors and professional services organizations who assist us in relation to the business or commercial purposes laid out herein
                  </p>
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>

      <p>
        Some of the information we collect also constitutes “sensitive personal information” under the CCPA, including information that reveals your
        social security, driver’s license, state identification card, or passport number, precise geolocation, racial or ethnic origin, sex life or
        sexual orientation, religious or philosophical beliefs, biometric information, and contents of your messages. We do not use sensitive personal
        information we collect for purposes other than providing and improving our services to you and protecting our services and our community, and
        we do not use sensitive personal information to infer characteristics about you.
      </p>
      <p>
        <br />
        <br />
      </p>
      <p>
        <b>How Long We Retain Your Information</b>
      </p>
      <p>
        We keep your personal information only as long as we need it for legitimate business purposes and as permitted by applicable law. If you
        decide to stop using our services, you can close your account and your profile will stop being visible to other users. Note that we will close
        your account automatically if you are inactive for a period of two years. After your account is closed, we will delete your personal
        information, as laid out below:
      </p>
      <p>
        1. To protect the safety and security of our users, we implement a safety retention window of up to three months following account closure.
        During this period, we keep your information in the event that it might be necessary to investigate unlawful or harmful conducts. The
        retention of information during this safety retention window is based on our legitimate interest as well as that of potential third-party
        victims.
      </p>
      <p>
        2. Once the safety retention window elapses, we delete your data and only keep limited information for specified purposes, as laid out below:
      </p>
      <p>
        a. We maintain limited data to comply with legal data retention obligations: in particular, we keep transaction data to comply with tax and
        accounting legal requirements, credit card information, if any, for the duration the user may challenge the transaction. We may also need to
        keep “traffic data” / logs for one1 year to comply with legal data retention obligations. We also keep records of consents users give us to
        evidence our compliance with applicable law.
      </p>
      <p>
        b. We maintain limited information on the basis of our legitimate interest: we keep customer care records and supporting data as well as
        imprecise location of download / purchase for 5five years to support our customer care decisions, enforce our rights and enable us to defend
        ourselves in the event of a claim, profile data for one year in anticipation of potential litigation, and for the establishment, exercise or
        defense of legal claims, and data necessary to prevent users who were banned or people who were found to be under the age of 18 from opening a
        new account, to ensure the safety and vital interests of our users.
      </p>
      <p>
        c. Finally, we maintain information on the basis of our legitimate interest where there is an outstanding or potential issue, claim or dispute
        requiring us to keep information (in particular if we receive a valid legal subpoena or request asking us to preserve data (in which case we
        would need to keep the data to comply with our legal obligations) or if data would otherwise be necessary as part of legal proceedings).
      </p>
      <p>
        <b>California Consumer Privacy Rights</b>
      </p>
      <p>
        Sales/Sharing Opt-out: We do not “sell” or “share” your personal information so no opt out choice is necessary. This means that we do not
        sell, share, rent, release, disclose, disseminate, make available, transfer, or otherwise communicate in any way your personal information to
        another company for monetary or other valuable consideration or for cross-context behavioral advertising.
      </p>
      <p>
        Know/Access: You have the right to request to learn more about the personal information we process about you and the right to request a copy
        of the specific pieces of personal information we have collected about you.
      </p>
      <p>Correction: You have the right to request that we correct inaccurate information we hold about you.</p>
      <p>Deletion: You have the right to request that we delete the personal information we have collected from you.</p>
      <p>
        Non-Discrimination: If you choose to exercise any of your rights under the CCPA, you have the right to not receive discriminatory treatment.
      </p>
      <p>
        <b>How to Submit a Rights Request:&nbsp;</b>We want you to be in control of your information and give you tools and options to access,
        correct, and delete your information. See Section 7 of our Privacy Policy to learn more about how you can manage your information.
      </p>
      <p>
        You can also submit a request directly to us. To submit an access, correction or deletion request at,{" "}
        <font color="#0563c1">
          <u>
            <a href="mailto:CustomerService@ShareMyInc.com">CustomerService@ShareMyInc.com</a>
          </u>
        </font>
        or{" "}
        <font color="#0563c1">
          <u>
            <a href="privacy-policy">contact us using the information available in Section 13 of the Privacy Policy</a>
          </u>
        </font>
        . To help protect your privacy and maintain security, we take steps to verify your identity before granting you access to your personal
        information or complying with your request. For example, submitting a request while logged into your account may be sufficient for
        verification, but sometimes we may ask you to provide proof of identity. You may also use an authorized agent to submit your request. If you
        do, we may ask for evidence that you have provided the agent valid power of attorney or other written permission to submit requests on your
        behalf, and we may also take steps to verify your identity. If you are an authorized agent seeking to make a request, please contact us as
        described above and include proof of your authorization.
      </p>
    </div>
  );
};

export default CaliforniaPolicy;
