import React from "react";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <div className="content">
      <h2>
        <Link to="/">Home</Link> / Privacy Policy
      </h2>
      <br />

      <p>
        <b>THIS DOCUMENT IS IN ADDITION TO THE PRIVACY STATEMENT IN THE TERMS OF USE POLICY</b>
      </p>
      <p align="center">
        <br />
        <br />
      </p>
      <p align="center">
        <b>OUR COMMITMENT TO YOUR PRIVACY</b>
      </p>
      <p>
        Your privacy is our upmost concern. Your privacy is at the foundation of our products and services you love. We go the extra mile so that you
        can fully trust us and more fully enjoy life by building new and exciting connections through activities you love.
      </p>
      <p>We honor your trust when you provide us with your information by protecting your privacy, and we do not take this lightly.</p>
      <p>
        <b>Our commitment to privacy.</b>
        &nbsp;We design all of our products and services with your privacy as a top priority. We involve professionals from various fields, including
        legal, security, product design and others to make sure that our products and services are designed with the utmost respect for your privacy.
      </p>
      <p>
        <b>Our commitment to transparency.</b>
        &nbsp;We want you to read our policies and understand our privacy practices.
      </p>
      <p>
        <b>Our commitment to security.</b>
        &nbsp;We have people dedicated to keeping your data safe and secure. We constantly update our security processes and invest in our security
        efforts to improve the safety of your information.
      </p>
      <p align="center">
        <b>PRIVACY POLICY</b>
      </p>
      <p>
        <b>If you are a California resident, please see our California Privacy Statement, which supplements this Privacy Policy.</b>
      </p>
      <p>
        We appreciate that you trust us with your information and we intend to always keep that trust. This starts with making sure you understand the
        information we collect, why we collect it, how it is used and your choices regarding your information. This Policy describes our privacy
        practices in plain language, keeping legal and technical jargon to a minimum.
      </p>
      <p>This Privacy Policy applies from March 15, 2023.</p>
      <p>The previous version of this Privacy Policy, will apply until then.</p>
      <ol>
        <li>
          <p>
            <Link to="/privacy-policy#Section1">
              <u>WHO WE ARE</u>
            </Link>
          </p>
        </li>
      </ol>
      <ol>
        <li>
          <p>
            <Link to="/privacy-policy#Section2">
              <u>WHERE THIS PRIVACY POLICY APPLIES</u>
            </Link>
          </p>
        </li>
      </ol>
      <ol>
        <li>
          <p>
            <Link to="/privacy-policy#Section3">
              <u>INFORMATION WE COLLECT</u>
            </Link>
          </p>
        </li>
      </ol>
      <ol>
        <li>
          <p>
            <Link to="/privacy-policy#Section5">
              <u>HOW WE USE INFORMATION</u>
            </Link>
          </p>
        </li>
      </ol>
      <ol>
        <li>
          <p>
            <Link to="/privacy-policy#Section6">
              <u>HOW WE SHARE INFORMATION</u>
            </Link>
          </p>
        </li>
      </ol>
      <ol>
        <li>
          <p>
            <Link to="/privacy-policy#Section7">
              <u>HOW SHAREMY PLATFORMS WORK TOGETHER</u>
            </Link>
          </p>
        </li>
      </ol>
      <ol>
        <li>
          <p>
            <Link to="/privacy-policy#Section8">
              <u>YOUR RIGHTS</u>
            </Link>
          </p>
        </li>
      </ol>
      <ol>
        <li>
          <p>
            <Link to="/privacy-policy#Section9">
              <u>HOW LONG WE RETAIN YOUR INFORMATION</u>
            </Link>
          </p>
        </li>
      </ol>
      <ol>
        <li>
          <p>
            <Link to="/privacy-policy#Section10">
              <u>CHILDREN’S PRIVACY</u>
            </Link>
          </p>
        </li>
      </ol>
      <ol>
        <li>
          <p>
            <Link to="/privacy-policy#Section11">
              <u>JOB CANDIDATES, CONTRACTORS AND VENDOR REPRESENTATIVES</u>
            </Link>
          </p>
        </li>
      </ol>
      <ol>
        <li>
          <p>
            <Link to="/privacy-policy#Section12">
              <u>PRIVACY POLICY CHANGES</u>
            </Link>
          </p>
        </li>
      </ol>
      <ol>
        <li>
          <p>
            <Link to="/privacy-policy#Section13">
              <u>HOW TO CONTACT US</u>
            </Link>
          </p>
        </li>
      </ol>
      <p>
        <b>1. WHO WE ARE</b>
      </p>
      <p>The company that is responsible for your information under this Privacy Policy is:</p>
      <p>ShareMy Inc</p>
      <p>PO BOX 267</p>
      <p>Lava Hot Springs ID</p>
      <p>83246</p>
      <p>
        <br />
      </p>
      <p>
        <b>2. WHERE THIS PRIVACY POLICY APPLIES</b>
      </p>
      <p>
        This Privacy Policy applies to websites, apps, events and other services we operate under the brand ShareMy. For simplicity, we refer to all
        of these as our “services” in this Privacy Policy. To make it extra clear, we’ve added links to this Privacy Policy on all applicable
        services.
      </p>
      <p>
        Some services may require their own unique privacy policy. If a service has its own privacy policy, then that policy -- not this Privacy
        Policy -- applies.
      </p>
      <p>
        <b>3. INFORMATION WE COLLECT</b>
      </p>
      <p>
        It goes without saying, we can’t help you develop great connections without some information about you, such as basic profile details and the
        types of activities you like and the types of people you want to do them with. We also collect information about your use of our services such
        as access logs, as well as information from third parties, like when you access our services through your social media account or when you
        upload information from your social media account to complete your profile. If you want additional info, we go into more detail below.
      </p>
      <p>
        <i>
          <b>Information you give us</b>
        </i>
      </p>
      <p>You choose to give us certain information when using our services. This includes:</p>
      <ul>
        <li>
          <p>
            When you create an account, you provide us with at least your login credentials and/or phone number and email address, as well as some
            basic details necessary for the service to work, such as your name and date of birth, and what activities you want to find.
          </p>
        </li>
      </ul>
      <ul>
        <li>
          <p>
            When you complete your profile, you have the option to share your religion, ethnicity and political views and other information with us.
            Where you provide such elements to us, you consent to us using it for the purposes identified and as laid out in this Privacy Policy. You
            can also share with us additional information, such as details on your personality, lifestyle, interests and other details about you, as
            well as content such as photos, videos and your bio. To add certain content, like pictures or videos, you may allow us to access your
            camera or photo album.
          </p>
        </li>
      </ul>
      <ul>
        <li>
          <p>
            When you subscribe to a paid service or make a purchase directly from us or through our third-party payment service (rather than through a
            platform such as iOS or Android), you provide us with information related to the purchases you make and our payment processor with
            information, such as your debit or credit card number or other financial information.
          </p>
        </li>
      </ul>
      <ul>
        <li>
          <p>
            When you participate in surveys, focus groups or market studies, you give us your insights into our products and services, responses to
            our questions and testimonials.
          </p>
        </li>
      </ul>
      <ul>
        <li>
          <p>When you choose to participate in our promotions, events or contests, we collect the information that you use to register or enter.</p>
        </li>
      </ul>
      <ul>
        <li>
          <p>If you contact our customer care team, we collect the information you give us during the interaction.</p>
        </li>
      </ul>
      <ul>
        <li>
          <p>
            If you share with us information about other people (for example, if you use contact details of a friend for a given feature or
            invitation), we process this information on your behalf in order to complete your request.
          </p>
        </li>
      </ul>
      <ul>
        <li>
          <p>
            Of course, we also process your chats with other users as well as the content you publish to operate and secure the services, and keep our
            community safe.
          </p>
        </li>
      </ul>
      <p>
        <i>
          <b>Information we receive from others</b>
        </i>
      </p>
      <p>In addition to the information you may provide us directly, we receive information about you from others, including:</p>
      <ul>
        <li>
          <p>
            <b>Users</b>
          </p>
        </li>
      </ul>
      <p>
        Users may provide information about you as they use our services, for instance as they interact with you or if they submit a report involving
        you.
      </p>
      <ul>
        <li>
          <p>
            <b>Social Media</b>
          </p>
        </li>
      </ul>
      <p>
        You may decide to share information with us through your social media accounts, for instance if you decide to create or log into your account
        via your social media or other accounts (e.g., Facebook, Google or Apple) or to upload onto our services information such as photos from one
        of your social media accounts (e.g., Facebook or Instagram).
      </p>
      <ul>
        <li>
          <p>
            <b>Other Partners</b>
          </p>
        </li>
      </ul>
      <p>
        We may receive information about you from our partners, including when accounts can be created through a partner’s service (in which case they
        pass along registration information to us) or where our ads are published on a partner’s service (in which case they may pass along details on
        a campaign’s success). Where legally allowed, we can also receive information about suspected or convicted bad actors from third parties as
        part of our efforts to ensure our users’ safety and security.
      </p>
      <p>
        <i>
          <b>Information generated or automatically collected when you use our services</b>
        </i>
      </p>
      <p>
        When you use our services, this generates technical data about which features you’ve used, how you’ve used them and the devices you use to
        access our services. See below for more details:
      </p>
      <ul>
        <li>
          <p>
            <b>Usage Information</b>
          </p>
        </li>
      </ul>
      <p>
        Using the services generates data about your activity on our services, for instance how you use them (e.g., when you logged in, features
        you’ve been using, actions taken, information shown to you, referring webpages address and ads that you interacted with) and your interactions
        with other users (e.g., users you connect and interact with, when you selected and exchanged invitations and messages with them).
      </p>
      <ul>
        <li>
          <p>
            <b>Device Information</b>
          </p>
        </li>
      </ul>
      <p>
        We collect information from and about the device(s) you use to access our services, including hardware and software information such as IP
        address, device ID and type, apps settings and characteristics, app crashes, advertising IDs (which are randomly generated numbers that you
        can reset by going into your device’s settings), and identifiers associated with cookies or other technologies that may uniquely identify a
        device or browser.
      </p>
      <ul>
        <li>
          <p>
            <b>Information collected by cookies and similar technologies</b>
          </p>
        </li>
      </ul>
      <p>
        We use and may allow others to use cookies and similar technologies (e.g., web beacons, pixels, SDKs) to recognize you and/or your device(s).
        You may read our Cookie Policy for more information on why we use them and how you can better control their use.
        <br />
        Some web browsers (including Safari, Internet Explorer, Firefox and Chrome) have a “Do Not Track” (“DNT”) feature that tells a website that a
        user does not want to have his or her online activity tracked. If a website that responds to a DNT signal receives a DNT signal, the browser
        can block that website from collecting certain information about the browser’s user. Not all browsers offer a DNT option and DNT signals are
        not yet uniform. For this reason, many businesses, including ours, do not currently respond to DNT signals..
      </p>
      <ul>
        <li>
          <p>
            <b>Other information with your consent</b>
          </p>
        </li>
      </ul>
      <p>
        <b>Precise geolocation data:&nbsp;</b>
        If you give us your consent, we can collect your precise geolocation (latitude and longitude) from your device. The collection of your
        geolocation may occur in the background even when you aren’t using the services if the permission you gave us expressly permits such
        collection. If you decline permission for us to collect your precise geolocation, we will not collect it, and our services that rely on
        precise geolocation may not be available to you.
      </p>
      <p>
        <b>Other information:&nbsp;</b>
        We may collect other information with your permission such as photos and videos (for instance, if you want to publish a photo or video or
        participate in streaming features on our services).
      </p>
      <p>
        <b>4. HOW WE USE INFORMATION</b>
      </p>
      <p>
        The main reason we use your information is to deliver and improve our services. Additionally, we use your information to help keep you safe,
        and to provide you with advertising that may be of interest to you. Read on for a more detailed explanation of the various reasons for which
        we use your information.
      </p>
      <p>
        <i>
          <b>A. To administer your account and provide our services to you</b>
        </i>
      </p>
      <ul>
        <li>
          <p>Create and manage your account</p>
        </li>
      </ul>
      <ul>
        <li>
          <p>Provide you with customer support and respond to your requests</p>
        </li>
      </ul>
      <ul>
        <li>
          <p>Personalize pricing, offer discounts and other promotions, and complete your transactions</p>
        </li>
      </ul>
      <ul>
        <li>
          <p>Communicate with you about our services</p>
        </li>
      </ul>
      <ul>
        <li>
          <p>Administer contests</p>
        </li>
      </ul>
      <p>
        <i>
          <b>B. To help you connect with other users</b>
        </i>
      </p>
      <ul>
        <li>
          <p>Recommend you other users and recommend other users to you</p>
        </li>
      </ul>
      <ul>
        <li>
          <p>Show users’ profiles to one another</p>
        </li>
      </ul>
      <ul>
        <li>
          <p>Enable users to search for and connect with users</p>
        </li>
      </ul>
      <p>
        <i>
          <b>C. To provide offers and operate advertising and marketing campaigns</b>
        </i>
      </p>
      <ul>
        <li>
          <p>Perform and measure the effectiveness of advertising campaigns on our services and marketing our services off our platform</p>
        </li>
      </ul>
      <ul>
        <li>
          <p>Communicate with you about products or services that we believe may interest you</p>
        </li>
      </ul>
      <p>
        <i>
          <b>D. To improve our services and develop new ones</b>
        </i>
      </p>
      <ul>
        <li>
          <p>Administer focus groups, market studies and surveys</p>
        </li>
      </ul>
      <ul>
        <li>
          <p>Review interactions with customer care professionals to improve our quality of service</p>
        </li>
      </ul>
      <ul>
        <li>
          <p>Understand how users typically use the services to improve them</p>
        </li>
        <li>
          <p>Develop new features and services</p>
        </li>
      </ul>
      <p>
        <i>
          <b>E. To prevent, detect and fight fraud and other illegal or unauthorized activities</b>
        </i>
      </p>
      <ul>
        <li>
          <p>
            Find and address ongoing, suspected or alleged violations of our&nbsp;
            <Link to="https://www.match.com/legalpolicy/TOUPolicy">
              <u>Terms of Use</u>
            </Link>
            , sometimes through the review of reports and interactions between members.
          </p>
        </li>
      </ul>
      <ul>
        <li>
          <p>
            Better understand and design countermeasures against violations of our&nbsp;
            <Link to="https://www.match.com/legalpolicy/TOUPolicy">
              <u>Terms of Use</u>
            </Link>
          </p>
        </li>
      </ul>
      <ul>
        <li>
          <p>
            Retain data related to violations of our&nbsp;
            <Link to="https://www.match.com/legalpolicy/TOUPolicy">
              <u>Terms of Use</u>
            </Link>
            &nbsp;to prevent against recurrences
          </p>
        </li>
      </ul>
      <ul>
        <li>
          <p>
            Enforce or exercise our rights, for example our&nbsp;
            <Link to="/legal-policy">
              <u>Terms of Use</u>
            </Link>
          </p>
        </li>
      </ul>
      <ul>
        <li>
          <p>Communicate to users who submit a report, including what we’ve done as a result of their submission.</p>
        </li>
      </ul>
      <p>
        <i>
          <b>F. To ensure legal compliance</b>
        </i>
      </p>
      <ul>
        <li>
          <p>Comply with legal requirements</p>
        </li>
      </ul>
      <ul>
        <li>
          <p>Assist law enforcement</p>
        </li>
      </ul>
      <p>
        <b>5. HOW WE SHARE INFORMATION</b>
      </p>
      <p>
        Realizing our goal to help you make connections with people and activities, the sharing of users’ information is imperative with other users.
        We also share some users’ information with service providers and partners who assist us in operating the services, with other ShareMy
        platforms for specified reasons as laid out below and, in some cases, legal authorities. Read on for more details about how your information
        is shared with others.
      </p>
      <ul>
        <li>
          <p>
            <b>With other users</b>
          </p>
        </li>
      </ul>
      <p>
        You share information with other users when you voluntarily disclose information on the service (including your public profile information).
        Please be careful with your information and make sure that the content you share is information about you that you are comfortable being
        visible.
      </p>
      <p>
        If you choose to limit the audience for all or part of your profile or for certain content or information about you, then it will be visible
        according to your settings.
      </p>
      <p>
        If someone submits a report involving you (such as a claim that you violated our Terms of Use), we may communicate to the reporting user the
        actions, if any, we took as a result of their report.
      </p>
      <ul>
        <li>
          <p>
            <i>
              <b>With our service providers and partners</b>
            </i>
          </p>
        </li>
      </ul>
      <p>
        We use vendors to help us operate, distribute, market and improve our services, such as data hosting and maintenance, analytics, customer
        care, marketing, advertising, payment processing and security operations. We also share information with vendors who distribute and assist us
        in advertising our services. For instance, we may share limited information on you in hashed, non-human readable form to advertising partners.
      </p>
      <p>
        We follow a strict vetting process prior to engaging any vendor or working with any partner. Our vendors and partners must agree to strict
        confidentiality obligations.
      </p>
      <ul>
        <li>
          <p>
            <i>
              <b>For corporate transactions</b>
            </i>
          </p>
        </li>
      </ul>
      <p>
        We may transfer your information if we are involved, whether in whole or in part, in a merger, sale, acquisition, divestiture, restructuring,
        reorganization, dissolution, bankruptcy or other change of ownership or control.
      </p>
      <ul>
        <li>
          <p>
            <i>
              <b>With law enforcement / when required by law</b>
            </i>
          </p>
        </li>
      </ul>
      <p>
        We may disclose your information if reasonably necessary: (i) to comply with a legal process, such as a court order, subpoena or search
        warrant, government / law enforcement investigation or other legal requirements; (ii) to assist in the prevention or detection of crime
        (subject in each case to applicable law); or (iii) to protect the safety of any person.
      </p>
      <ul>
        <li>
          <p>
            <i>
              <b>To enforce legal rights</b>
            </i>
          </p>
        </li>
      </ul>
      <p>
        We may also share information: (i) if disclosure would mitigate our liability in an actual or threatened lawsuit; (ii) as necessary to protect
        our legal rights and legal rights of our users, business partners or other interested parties; (iii) to enforce our agreements with you; and
        (iv) to investigate, prevent, or take other action regarding illegal activity, suspected fraud or other wrongdoing.
      </p>
      <ul>
        <li>
          <p>
            <i>
              <b>With your consent or at your request</b>
            </i>
          </p>
        </li>
      </ul>
      <p>
        We may ask for your consent to share your information with third parties. In any such case, we will make it clear why we want to share the
        information.
      </p>
      <p>
        We may use and share non-personal information (meaning information that, by itself, does not identify who you are such as device information,
        general demographics, general behavioral data, geolocation in de-identified form), as well as personal information in hashed, non-human
        readable form, under any of the above circumstances. We may also share this information with other ShareMy platforms and third parties
        (notably advertisers) to develop and deliver targeted advertising on our services and on websites or applications of third parties, and to
        analyze and report on advertising you see. We may combine this information with additional non-personal information or personal information in
        hashed, non-human readable form collected from other sources. More information on our use of cookies and similar technologies can be found in
        our&nbsp;
        <Link to="/cookie-policy">
          <u>Cookie Policy</u>
        </Link>
        .
      </p>
      <p>
        <b>6. HOW SHAREMY PLATFORMS WORK TOGETHER</b>
      </p>
      <p>
        We are part of the&nbsp;
        <Link to="/our-company">
          <u>ShareMy Inc platforms such as ShareMy Mountain ShareMy River, ShareMy Wave ShareMy Rope, ShareMy Snow, ShareMy Fun.</u>
        </Link>
      </p>
      <p>We may share information about you with our affiliates and they may share information about you with us, for the reasons laid out below:</p>
      <ul>
        <li>
          <p>
            To make all ShareMy platforms safer, for instance by making sure that when a bad actor is found on one ShareMy platform, that bad actor
            can be banned from all.
          </p>
        </li>
      </ul>
      <ul>
        <li>
          <p>
            To assist each other in data processing operations, as service providers. This assistance may include technical processing operations,
            such as data hosting and maintenance, customer care, marketing and targeted advertising, analytics, finance and accounting, payment
            processing, service improvement, data security and fighting against spam, abuse, fraud, infringement and other wrongdoings.
          </p>
        </li>
      </ul>
      <ul>
        <li>
          <p>
            To improve your chances at building great connections with others, we may make you visible on other ShareMy services or allow you to
            benefit from cross-platform functionalities. We will of course comply with applicable law and, where relevant, notify you of any such
            opportunity and allow you to agree or to refuse.
          </p>
        </li>
      </ul>
      <ul>
        <li>
          <p>
            If you are located in the USA excluding California, Colorado, Utah, Connecticut and Virginia, to personalize your experience on ShareMy
            platforms, including how you’re shown to other users and how others are shown to you, prices, discounts, ads and content you experience,
            to improve our marketing campaigns.
          </p>
        </li>
      </ul>
      <ul>
        <li>
          <p>For other legitimate business purposes including corporate audit, analysis and consolidated reporting.</p>
        </li>
      </ul>
      <p>
        <b>7. YOUR RIGHTS</b>
      </p>
      <p>We want you to be in control of your information, so we want to remind you of the following options and tools available to you:</p>
      <ul>
        <li>
          <p>
            <a name="_Hlk129454207"></a>
            <u>Access / Update tools in the service</u>. Tools and account settings can help you access, rectify or remove information that you
            provided to us and that’s associated with your account directly within the service. If you have any questions on those tools and settings,
            please contact our customer care team for help at{" "}
            <u>
              <a href="mailto:CustomerService@ShareMyInc.com">CustomerService@ShareMyInc.com</a>
            </u>
            &nbsp;
          </p>
        </li>
      </ul>
      <ul>
        <li>
          <p>
            <u>Device permissions</u>. Mobile platforms can have permission systems for specific types of device data and notifications, such as phone
            contacts, pictures, location services, push notifications and advertising identifiers. You can change your settings on your device to
            either consent or oppose the collection or processing of the corresponding information or the display of the corresponding notifications.
            Of course, if you do that, certain services may lose functionality.
          </p>
        </li>
      </ul>
      <ul>
        <li>
          <p>
            <u>Uninstall</u>. You can stop all information collection by an app by uninstalling it using the standard uninstall process for your
            device. Remember that uninstalling an app does NOT close your account. To close your account, please use the corresponding functionality
            on the service
          </p>
        </li>
      </ul>
      <ul>
        <li>
          <p>
            <u>Account closure</u>. You can close your account by using the corresponding functionality directly on the service.
          </p>
        </li>
      </ul>
      <p>We also want you to be aware of your privacy rights. Here are a few key points to remember:</p>
      <ul>
        <li>
          <p>
            <u>Access/know</u>. You may have the right to request a copy of the information we keep about you, and in certain circumstances to receive
            this in a portable format. To do so, please contact us at
            <u>
              <a href="mailto:CustomerService@ShareMyInc.com">CustomerService@ShareMyInc.com</a>
            </u>
            .
          </p>
        </li>
      </ul>
      <ul>
        <li>
          <p>
            <u>Delete/erase</u>. You may request that we delete the personal information we keep about you. You can exercise your right to delete by
            submitting a request at{" "}
            <u>
              <a href="mailto:CustomerService@ShareMyInc.com">CustomerService@ShareMyInc.com</a>
            </u>
            .
          </p>
        </li>
      </ul>
      <ul>
        <li>
          <p>
            <u>Correct/rectify/update</u>
            You can correct most information you provided to us by editing your profile directly in the service. If you believe the information we
            hold about you is inaccurate, you may contact us at
            <u>
              <a href="mailto:CustomerService@ShareMyInc.com">CustomerService@ShareMyInc.com</a>
            </u>
            to rectify it.
          </p>
        </li>
      </ul>
      <p>
        <u>Object/restrict</u>
        You may also have the right to object to or request that we restrict certain processing. To do so, please contact us at
        <u>
          <a href="mailto:CustomerService@ShareMyInc.com">CustomerService@ShareMyInc.com</a>
        </u>
        . For your protection and the protection of all of our users, we may ask you to provide proof of identity before we can answer the above
        requests.
      </p>
      <p>
        Keep in mind, we may reject requests, including if we are unable to authenticate you, if the request is unlawful or invalid, or if it may
        infringe on trade secrets or intellectual property or the privacy or other rights of someone else. If you wish to receive information relating
        to another user, such as a copy of any messages you received from them through our service, the other user will have to contact us to submit a
        separate request for their information. We may also ask them to provide proof of identity before we can answer the request.
      </p>
      <p>
        Also, we may not be able to accommodate certain requests to object to or restrict the processing of personal information, notably where such
        requests would not allow us to provide our service to you anymore. For instance, we cannot provide our service if we do not have your date of
        birth and thus cannot ensure that you are 18 years of age or older.
      </p>
      <p>
        In certain jurisdictions, you have a right to lodge a complaint with the appropriate data protection authority if you have concerns about how
        we process your personal information. If you are a resident of Virginia, USA, if we deny your privacy request, you may be able to appeal by
        contacting us at{" "}
        <u>
          <a href="mailto:CustomerService@ShareMyInc.com">CustomerService@ShareMyInc.com</a>
        </u>
        , and explicitly referencing “Privacy Appeal Action”. If you have concerns about the result of your appeal you may contact the attorney
        general for your state.
      </p>
      <p>
        <b>8. HOW LONG WE RETAIN YOUR INFORMATION</b>
      </p>
      <p>
        We keep your personal information only as long as we need it for legitimate business purposes (as laid out in&nbsp;
        <Link to="/privacy-policy#Section4">
          <u>Section 4</u>
        </Link>
        ) and as permitted by applicable law. If you decide to stop using our services, you can close your account and your profile will stop being
        visible to other users. Note that we will close your account automatically if you are inactive for a period of two years. After your account
        is closed, we will delete your personal information, as laid out below:
      </p>
      <ol>
        <li>
          <p>
            To protect the safety and security of our users, we implement a safety retention window of six months following account closure. During
            this period, we keep your information in the event that it might be necessary to investigate unlawful or harmful conducts. The retention
            of information during this safety retention window is based on our legitimate interest as well as that of potential third-party victims.
          </p>
        </li>
      </ol>
      <ol>
        <p>
          Once the safety retention window elapses, we delete your data and only keep limited information for specified purposes, as laid out below:
        </p>
        <ul>
          <li>
            <p>
              We maintain limited data to comply with legal data retention obligations: in particular, we keep transaction data to comply with tax and
              accounting legal requirements, credit card information, if any, for the duration the user may challenge the transaction and “traffic
              data” / logs for one year to comply with legal data retention obligations. We also keep records of consents users give us for five years
              to evidence our compliance with applicable law.
            </p>
          </li>
          <li>
            <p>
              We maintain limited information on the basis of our legitimate interest: we keep customer care records and supporting data as well as
              imprecise location of download/purchase for five years to support our customer care decisions, enforce our rights and enable us to
              defend ourselves in the event of a claim, profile data for one year in anticipation of potential litigation, for the establishment,
              exercise or defense of legal claims, and data necessary to prevent users who were banned or people who were found to be under the age of
              18 from opening a new account, to ensure the safety and vital interests of our users.
            </p>
          </li>
          <li>
            <p>
              Finally, we maintain information on the basis of our legitimate interest where there is an outstanding or potential issue, claim or
              dispute requiring us to keep information (in particular if we receive a valid legal subpoena or request asking us to preserve data (in
              which case we would need to keep the data to comply with our legal obligations) or if data would otherwise be necessary as part of legal
              proceedings).
            </p>
          </li>
        </ul>
      </ol>
      <p>
        <b>9. CHILDREN'S PRIVACY</b>
      </p>
      <p>
        Our services are restricted to individuals who are 18 years of age or older. We do not permit individuals under the age of 18 on our platform.
        If you suspect that a user is under the age of 18, please use the reporting mechanism available on the service.
      </p>
      <p>
        <b>10. JOB CANDIDATES, CONTRACTORS AND VENDOR REPRESENTATIVES</b>
      </p>
      <p>
        If you are a job applicant, the personal information we process about you may vary depending on the job you seek but typically includes what
        you provide to us as part of your job application as well as professional qualifications, background and reference information that recruiters
        or other third parties share with us. We use this information to support the recruitment process, which may lead to an employment contract.
        For contractors and vendor representatives, we may process identification information and work-related information, as necessary to manage our
        relationship with you and your employer, which is necessary for the performance of the services agreement, and to establish, exercise or
        defend potential legal claims. We may share personal information with service providers that assist us with recruitment and technical data
        processing operations as well as with ShareMy. We keep your personal information only as long as necessary for those purposes.
      </p>
      <p>
        <b>11. PRIVACY POLICY CHANGES</b>
      </p>
      <p>
        Because we’re in the business of finding new and innovative ways to help you build great connections and because we want to make sure
        explanations about our data practices remain up-to-date, this policy may change over time. We will notify you so that you can review the
        changes.
      </p>
      <p>
        <b>13. HOW TO CONTACT US</b>
      </p>
      <p>If you have questions about this Privacy Policy, you have different options below:</p>
      <p>
        <b>Online:</b>
        &nbsp; at
        <u>
          <a href="mailto:CustomerService@ShareMyInc.com">CustomerService@ShareMyInc.com</a>
        </u>
      </p>
      <p>
        <a name="_Hlk129455213"></a>

        <b>By post:</b>
      </p>
      <p> ShareMy</p>
      <p> PO BOX 267</p>
      <p> Lava Hot Springs ID</p>
      <p> 83246</p>
    </div>
  );
};

export default PrivacyPolicy;
